import {
    Stack
} from '@chakra-ui/react';
import { useState } from 'react';
import { TelemetryContext } from '../../../context/TelemetryContext';
import TelemetryBatteryCharges from './TelemetryBatteryCharges';
import TelemetryHeader from './TelemetryHeader';
import TelemetryLoadTrace from './TelemetryLoadTrace';

const TelemetryInfo = () => {
    const [includeFuture, setIncludeFuture] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [everySecond, setEverySecond] = useState<number>(250);

    return (
        <TelemetryContext.Provider
            value={{
                includeFuture,
                setIncludeFuture,
                startDate,
                setStartDate,
                endDate,
                setEndDate,
                everySecond,
                setEverySecond
            }}
        >
            <Stack>
                <TelemetryHeader />
                <TelemetryLoadTrace />
                <TelemetryBatteryCharges />
            </Stack>
        </TelemetryContext.Provider>
    );
};

export default TelemetryInfo;

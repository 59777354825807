import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorMode, useColorModeValue, VStack } from '@chakra-ui/react';
import CustomCard from '../components/CustomCard';
import Page from '../components/Page';
import SwitchWithText from '../components/SwitchWithText';
import { useSettings } from '../context/SettingsContext';

interface SettingProps {
    title: string,
    description: string,
    children?: React.ReactNode
};
const Setting = ({ title, description, children }: SettingProps) => (
    <Box w="100%">
        <CustomCard>
            <Flex w={"100%"} flexDir={{ base: "column", md: "row" }} gap={"16px"} >
                <Box w={{ base: "100%", md: "60%" }}>
                    <Text fontSize='lg' as="h3" fontWeight="semibold" >{title}</Text>
                    <Text fontSize='lg' fontWeight="medium" color={useColorModeValue("gray.500", "gray.300")}>
                        {description}
                    </Text>
                </Box>
                <Flex align="center" justify="center" w={{ base: "100%", md: "40%" }}>
                    {children}
                </Flex>
            </Flex>
        </CustomCard>
    </Box>
);


const GeneralSettings = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const {
        showTimeInfo,
        setShowTimeInfo,
    } = useSettings();
    return (
        <VStack align="flex-start" w="100%">
            <Setting
                title="Darkmode"
                description="When the dark mode is enabled, the application's user interface has a dark design. This also affects telemetry graphs."
            >
                <SwitchWithText
                    isChecked={colorMode === 'dark'}
                    onChange={toggleColorMode}
                    text={"Enable"}
                />
            </Setting>
            <Setting
                title="Timeinfo"
                description="When this option is enabled, a box appears in the top navigation that displays your current local time and the time in UTC. If display problems occur on mobile devices, this option should be disabled."
            >
                <SwitchWithText
                    isChecked={showTimeInfo === true}
                    onChange={() => setShowTimeInfo((old) => !old)}
                    text={"Show"}
                />
            </Setting>
        </VStack>
    );
}

const RealtimeSettings = () => {
    const {
        debug,
        setDebug,
        initialShowSatLocation,
        setInitialShowSatLocation,
    } = useSettings();
    return (

        <VStack align="flex-start">
            <Setting
                title="Initial 3D Visualization"
                description="If this setting is activated, the visualization of the location is calculated via caesium and displayed directly after opening the detail page for a satellite."
            >
                <SwitchWithText
                    isChecked={initialShowSatLocation === true}
                    onChange={() => setInitialShowSatLocation((old) => !old)}
                    text={"Enable"}
                />
            </Setting>
            <Setting
                title="Godmode"
                description="
                By activating the Godmode, the user can interact with the satellite in a non-realistic way.
                He can download telemetry data or upload operations even though the satellite is not in range of a ground station.
                In addition, Godmode must be enabled to delete a satellite from the application.
                "
            >
                <SwitchWithText
                    isChecked={debug === true}
                    onChange={() => setDebug((old) => !old)}
                    text={"Enable"}
                />
            </Setting>
        </VStack>
    );
}

const SettingsPage = () => {
    return (
        <Page title="Settings">
            <Tabs variant='line' colorScheme='gray'>
                <TabList>
                    <Tab>General</Tab>
                    <Tab>Realtime</Tab>
                    <Tab>Simulator</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p={0} pt={5}>
                        <Text mt={"5px"} as="h2" fontWeight="semibold" fontSize='3xl'>General settings</Text>
                        <Text mb={"15px"} fontWeight="light" fontSize='lg' color={useColorModeValue("gray.600", "gray.300")}>This settings apply to the general application.</Text>
                        <GeneralSettings />
                    </TabPanel>
                    <TabPanel p={0} pt={5}>
                        <Text mt={"5px"} as="h2" fontWeight="semibold" fontSize='3xl'>Realtime settings</Text>
                        <Text mb={"15px"} fontWeight="light" fontSize='lg' color={useColorModeValue("gray.600", "gray.300")}>This settings apply just to the Realtime Satellite Viewer application.</Text>
                        <RealtimeSettings />
                    </TabPanel>
                    <TabPanel p={0} pt={5}>
                        <Text mt={"5px"} as="h2" fontWeight="semibold" fontSize='3xl'>Simulator settings</Text>
                        <Text mb={"15px"} fontWeight="light" fontSize='lg' color={useColorModeValue("gray.600", "gray.300")}>This settings apply just to the Simulator application.</Text>
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </Page>
    );
};

export default SettingsPage;

import { useColorMode } from '@chakra-ui/react';
import { ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { Route, Switch } from 'react-router-dom';
import config from '../Config';
import AppSelectPage from '../pages/AppSelectPage';
import LogPage from '../pages/LogPage';
import NotFound from '../pages/NotFound';
import SettingsPage from '../pages/SettingsPage';
import TokenPage from '../pages/TokenPage';
import GeneralNav from './GeneralNav';
import RealTimeNav from './RealTimeNav';
import PrivateRoute from './routing/PrivateRoute';
import RealtimeRouter from './routing/RealtimeRouter';
import SimulatorRouter from './routing/SimulatorRouter';
import UnauthenticatedOnlyRoute from './routing/UnauthenticatedOnlyRoute';

const MainRouter = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    return (
        //Add Mantine ColorSchemeProvider and MantineProvider, as it cannot be in the same file as the chakra provider to access its context
        <ColorSchemeProvider colorScheme={colorMode} toggleColorScheme={toggleColorMode}>
            <MantineProvider theme={{ colorScheme: colorMode }}>
                <Switch>
                    <UnauthenticatedOnlyRoute exact path={config.LOGIN_ROUTE} redirectPath={'/'}>
                        <TokenPage />
                    </UnauthenticatedOnlyRoute>
                    <PrivateRoute exact path={config.SETTINGS_ROUTE} redirectPath={config.LOGIN_ROUTE}>
                        <GeneralNav />
                        <SettingsPage />
                    </PrivateRoute>
                    <PrivateRoute exact path={config.LOGS_ROUTE} redirectPath={config.LOGIN_ROUTE}>
                        <GeneralNav />
                        <LogPage />
                    </PrivateRoute>
                    <PrivateRoute exact path="/" redirectPath={config.LOGIN_ROUTE}>
                        <GeneralNav />
                        <AppSelectPage />
                    </PrivateRoute>
                    <PrivateRoute path={config.REALTIME_PREFIX} redirectPath={config.LOGIN_ROUTE}>
                        <RealTimeNav />
                        <RealtimeRouter />
                    </PrivateRoute>
                    <PrivateRoute path={config.SIMULATOR_PREFIX} redirectPath={config.LOGIN_ROUTE}>
                        <GeneralNav />
                        <SimulatorRouter />
                    </PrivateRoute>
                    <Route path="*">
                        <GeneralNav />
                        <NotFound />
                    </Route>
                </Switch>
            </MantineProvider>
        </ColorSchemeProvider>
    );
};

export default MainRouter;

import { useEffect, useState } from 'react';

interface Props {
    type: 'LOCAL' | 'UTC';
}

export const useDate = ({ type }: Props) => {
    const locale = 'en';
    const [today, setDate] = useState(new Date()); // Save the current date to be able to trigger an update

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 60 * 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const day = today.toLocaleDateString(locale, { weekday: 'long' });
    const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(
        locale,
        { month: 'long' }
    )}\n\n`;

    const time = today.toLocaleTimeString(locale, {
        hour: 'numeric',
        hour12: true,
        minute: 'numeric',
        timeZone: type === 'UTC' ? 'UTC' : 'Europe/Berlin',
    });

    return {
        date,
        time,
    };
};

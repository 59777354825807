import {
    ChevronDownIcon,
    ChevronRightIcon,
    CloseIcon,
    HamburgerIcon,
} from '@chakra-ui/icons';
import {
    Box,
    Collapse,
    Flex,
    HStack,
    Icon,
    IconButton,
    Link,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { Link as LinkRouter, useHistory } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useSettings } from '../../context/SettingsContext';
import LogoutButton from './LogoutButton';
import { NavItem, NAV_ITEMS } from './nav_items';
import TimeInfo from './TimeInfo';

const SideNav = () => {
    const { isOpen, onToggle } = useDisclosure();
    const { showTimeInfo } = useSettings();

    return (
        <Box>
            <Flex
                bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}
                justify={'space-between'}
            >
                <Flex
                    flex={{ md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}
                >
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? (
                                <CloseIcon w={3} h={3} />
                            ) : (
                                <HamburgerIcon w={5} h={5} />
                            )
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex
                    justify={{ base: 'center', md: 'start' }}
                    alignItems={'center'}
                >
                    <LinkRouter to="/">
                        <Box cursor={'pointer'}>
                            <Text
                                textAlign={useBreakpointValue({
                                    base: 'center',
                                    md: 'left',
                                })}
                                fontWeight={'bold'}
                                fontFamily={'heading'}
                                color={useColorModeValue('gray.800', 'white')}
                            >
                                🛰️ SatSim
                            </Text>
                        </Box>
                    </LinkRouter>
                    <Flex display={{ base: 'none', md: 'flex' }} ml={'10'}>
                        <DesktopNav />
                    </Flex>
                </Flex>
                <HStack spacing="5">
                    {showTimeInfo && <TimeInfo />}
                    <LogoutButton />
                </HStack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
};

const DesktopNav = () => {
    const { isAuthenticated } = useAuth();
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');
    const history = useHistory();

    return (
        <Stack direction={'row'} spacing={4} overflowY="hidden">
            {NAV_ITEMS.map(
                (navItem) =>
                    ((isAuthenticated && !navItem.hideAuthenticated) ||
                        (!isAuthenticated && !navItem.hideUnauthenticated)) && (
                        <Box
                            key={navItem.label}
                            cursor="pointer"
                            onClick={() => {
                                if (navItem.href) history.push(navItem.href);
                            }}
                        >
                            <Popover
                                trigger={'hover'}
                                placement={'bottom-start'}
                            >
                                <PopoverTrigger>
                                    <Box
                                        as='a'
                                        href={navItem.href ?? '#'}
                                        fontSize={'sm'}
                                        fontWeight={'semibold'}
                                        color={linkColor}
                                        _hover={{
                                            textDecoration: 'none',
                                            color: linkHoverColor,
                                        }}
                                        px={2}
                                        py={2}
                                    >
                                        {navItem.label}
                                    </Box>
                                </PopoverTrigger>

                                {navItem.children && (
                                    <PopoverContent
                                        border={0}
                                        boxShadow={'xl'}
                                        bg={popoverContentBgColor}
                                        p={4}
                                        rounded={'xl'}
                                        minW={'sm'}
                                    >
                                        <Stack>
                                            {navItem.children.map((child) => (
                                                <DesktopSubNav
                                                    key={child.label}
                                                    {...child}
                                                />
                                            ))}
                                        </Stack>
                                    </PopoverContent>
                                )}
                            </Popover>
                        </Box>
                    )
            )}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <LinkRouter to={href ?? ''}>
            <Link
                role={'group'}
                display={'block'}
                p={2}
                rounded={'md'}
                as={'div'}
                _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
            >
                <Stack direction={'row'} align={'center'}>
                    <Box>
                        <Text
                            transition={'all .3s ease'}
                            _groupHover={{ color: 'pink.400' }}
                            fontWeight={500}
                        >
                            {label}
                        </Text>
                        <Text fontSize={'sm'}>{subLabel}</Text>
                    </Box>
                    <Flex
                        transition={'all .3s ease'}
                        transform={'translateX(-10px)'}
                        opacity={0}
                        _groupHover={{
                            opacity: '100%',
                            transform: 'translateX(0)',
                        }}
                        justify={'flex-end'}
                        align={'center'}
                        flex={1}
                    >
                        <Icon
                            color={'pink.400'}
                            w={5}
                            h={5}
                            as={ChevronRightIcon}
                        />
                    </Flex>
                </Stack>
            </Link>
        </LinkRouter>
    );
};

const MobileNav = () => {
    const { isAuthenticated } = useAuth();

    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}
        >
            {NAV_ITEMS.map(
                (navItem) =>
                    ((isAuthenticated && !navItem.hideAuthenticated) ||
                        (!isAuthenticated && !navItem.hideUnauthenticated)) && (
                        <MobileNavItem key={navItem.label} {...navItem} />
                    )
            )}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isAuthenticated } = useAuth();
    const { isOpen, onToggle } = useDisclosure();
    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}
                >
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse
                in={isOpen}
                animateOpacity
                style={{ marginTop: '0!important' }}
            >
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}
                >
                    {children &&
                        children.map(
                            (child) =>
                                ((isAuthenticated &&
                                    !child.hideAuthenticated) ||
                                    (!isAuthenticated &&
                                        !child.hideUnauthenticated)) && (
                                    <Link
                                        key={child.label}
                                        py={2}
                                        href={child.href}
                                    >
                                        {child.label}
                                    </Link>
                                )
                        )}
                </Stack>
            </Collapse>
        </Stack>
    );
};

export default SideNav;

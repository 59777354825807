import { Text, useConst, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { BreadcrumbItemProps } from '../components/Breadcrumb';
import Page from '../components/Page';

const NotFound = ({ prefix = "" }: { prefix?: string }) => {
    const breadCrumbItems: BreadcrumbItemProps[] = useConst(() => {
        return [
            {
                name: 'Home',
                to: `${prefix}/`,
            },
            {
                name: 'Not found',
                to: `${prefix}/`,
            },
        ];
    });
    return (
        <Page breadCrumbItems={breadCrumbItems}>
            <VStack h="100%">
                <Text fontSize="3xl">Page not found.</Text>
                <Link to={`${prefix}/`}>
                    <Text fontSize="1xl">Go back to home.</Text>
                </Link>
            </VStack>
        </Page>
    );
};

export default NotFound;

import { Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useEffect } from 'react';
import CustomCard from '../../components/CustomCard';
import CustomReactDatepicker from '../../components/CustomReactDatepicker';
import { useSimulationCreateContext } from '../../context/SimulationCreateContext';

const ERROR1 = 'StartDate';
const ERROR2 = 'EndDate';
const ERROR3 = 'EndDateAfterStartDate';

const SimulationTimesTab = () => {
    const { startDate, setStartDate, endDate, setEndDate, addError, removeError, isError } =
        useSimulationCreateContext();

    // ERROR HANDLING
    useEffect(() => {
        if (!startDate) {
            addError(ERROR1);
        } else {
            removeError(ERROR1);
        }

        if (!endDate) {
            addError(ERROR2);
        } else {
            removeError(ERROR2);
        }

        if (!!(startDate && endDate && startDate.getTime() > endDate.getTime())) {
            addError(ERROR3);
        } else {
            removeError(ERROR3);
        }
    }, [startDate, endDate, addError, removeError]);

    return (
        <Stack pb={6}>
            <Text mt={'5px'} as="h2" fontWeight="semibold" fontSize="3xl">
                Simulation times
            </Text>
            <Text mb={'15px'} fontWeight="light" fontSize="lg" color={useColorModeValue('gray.600', 'gray.300')}>
                Here the start and end time of the simulation is given. If the start time is in the past, it is possible
                that there is no battery data for a satellite in real-time mode, then only its initial battery data can
                be taken.
            </Text>
            <CustomCard name="Start time [UTC]">
                <CustomReactDatepicker
                    selected={startDate}
                    onChange={setStartDate}
                    showWeekNumbers
                    showTimeSelect
                    dateFormat="Pp"
                    withPortal
                    error={startDate === null}
                />
                {isError(ERROR1) && <Text color="red.500">Start time is required.</Text>}
            </CustomCard>
            <CustomCard name="End time [UTC]">
                <CustomReactDatepicker
                    selected={endDate}
                    onChange={setEndDate}
                    showWeekNumbers
                    showTimeSelect
                    dateFormat="Pp"
                    withPortal
                    error={endDate === null || (startDate && startDate.getTime() > endDate.getTime())}
                />
                {isError(ERROR2) && <Text color="red.500">End time is required.</Text>}
                {isError(ERROR3) && <Text color="red.500">The end time must be after the start time.</Text>}
            </CustomCard>
        </Stack>
    );
};

export default SimulationTimesTab;

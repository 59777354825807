import {
    Breadcrumb as ChakraBreadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link, useLocation } from 'react-router-dom';

export type BreadcrumbItemProps = {
    name: string;
    to: string;
};

interface Props {
    items: BreadcrumbItemProps[];
}

const Breadcrumb = ({ items }: Props) => {
    const location = useLocation();

    return (
        <ChakraBreadcrumb separator={<ChevronRightIcon color='gray.500' />}>
            {items.map((item) => (
                <BreadcrumbItem key={item.name}>
                    <BreadcrumbLink
                        as={Link}
                        to={item.to}
                        isCurrentPage={location.pathname === item.to}
                    >
                        {item.name}
                    </BreadcrumbLink>
                </BreadcrumbItem>
            ))}
        </ChakraBreadcrumb>
    );
};

export default Breadcrumb;

import {
    Box,
    Text,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import config from '../../Config';
import { useAuth } from '../../context/AuthContext';

const LogoutButton = () => {
    const { isAuthenticated, logout } = useAuth();
    const history = useHistory();

    return (
        <Box
            cursor={'pointer'}
            p={2}
            onClick={() => {
                if (isAuthenticated) {
                    logout();
                } else {
                    history.push(config.LOGIN_ROUTE);
                }
            }}
        >
            <Text
                textAlign={useBreakpointValue({
                    base: 'center',
                    md: 'left',
                })}
                fontWeight={'bold'}
                fontFamily={'heading'}
                color={useColorModeValue('gray.800', 'white')}
            >
                {isAuthenticated ? 'Logout' : 'Login'}
            </Text>
        </Box>
    );
};

export default LogoutButton;

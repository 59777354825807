import { createContext, useContext } from 'react';

type ContextType = {
    debug: boolean;
    setDebug: (value: boolean | ((val: boolean) => boolean)) => void;
    initialShowSatLocation: boolean;
    setInitialShowSatLocation: (
        value: boolean | ((val: boolean) => boolean)
    ) => void;
    showTimeInfo: boolean;
    setShowTimeInfo: (value: boolean | ((val: boolean) => boolean)) => void;
};

export const SettingsContext = createContext<ContextType>({
    debug: false,
    setDebug: () => {},
    initialShowSatLocation: false,
    setInitialShowSatLocation: () => {},
    showTimeInfo: true,
    setShowTimeInfo: () => {},
});

export const useSettings = () => useContext(SettingsContext);

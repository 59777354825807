import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Input,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbItemProps } from '../../components/Breadcrumb';
import Page from '../../components/Page';
import config from '../../Config';
import { useAuth } from '../../context/AuthContext';
import Battery from '../../types/Battery';
import Component from '../../types/Component';
import AddGroundStation from './AddGroundStation';
import BatteryModal from './BatteryModal';
import ComponentTable from './ComponentTable';
import ComponentModal from './ComponentTable/ComponentModal';

const breadCrumbItems: BreadcrumbItemProps[] = [
    {
        name: 'Home',
        to: config.REALTIME_PREFIX,
    },
    {
        name: 'Satellites',
        to: `${config.REALTIME_PREFIX}/satellites`,
    },
    {
        name: 'Create',
        to: `${config.REALTIME_PREFIX}/satellites/create`,
    },
];

interface ItemProps {
    name: String;
    value: String;
}
const Item = ({ name, value }: ItemProps) => {
    return (
        <GridItem>
            <HStack>
                <Box fontWeight="light">{name}</Box>
                <Box fontSize={'small'}>{value}</Box>
            </HStack>
        </GridItem>
    );
};

const SatelliteCreatePage = () => {
    const { axiosInstance } = useAuth();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const bgColor = useColorModeValue('white', 'gray.700');

    const [satName, setSatName] = useState('');
    const [tleUrl, setTleUrl] = useState('');

    const [error, setError] = useState('');

    const [battery, setBattery] = useState<Battery | null>(null);
    const [isBatteryModalOpen, setIsBatteryModalOpen] = useState<boolean>(false);

    const [components, setComponents] = useState<Component[]>([
        {
            name: 'SolarPanel',
            background: false,
            slewingAfter: 0,
            slewingBefore: 0,
            powerConsumption: -1000,
            isStatic: true,
        },
        {
            name: 'BackgroundLoad',
            background: true,
            slewingAfter: 0,
            slewingBefore: 0,
            powerConsumption: 1000,
            isStatic: true,
        },
        {
            name: 'Slewing',
            background: false,
            slewingAfter: 0,
            slewingBefore: 0,
            powerConsumption: 1000,
            isStatic: true,
        },
    ]);
    const [isComponentCreationOpen, setIsComponentCreationOpen] = useState<boolean>(false);

    const [satelliteGroundStationIds, setSatelliteGroundStationIds] = useState<number[]>([]);

    const setBatteryHandler = (bat: Battery) => {
        setBattery(bat);
    };

    const addComponent = (comp: Component): boolean => {
        if (components.findIndex((existingComp) => existingComp.name === comp.name) !== -1) return false;
        setComponents((comps) => [...comps, comp]);
        return true;
    };

    const handleSubmit = async () => {
        setError('');
        setLoading(true);
        try {
            await axiosInstance.post('/api/satellites', {
                name: satName,
                tleURL: tleUrl,
                batteryConfiguration: {
                    ...battery,
                },
                components: components.map((comp) => {
                    delete comp.isStatic;
                    return comp;
                }),
                groundStationIds: satelliteGroundStationIds,
            });
            history.push(`${config.REALTIME_PREFIX}/satellites`);
        } catch (error: any) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
                setError(error.message);
            }
            setLoading(false);
        }
    };

    return (
        <>
            <BatteryModal
                isOpen={isBatteryModalOpen}
                onClose={() => setIsBatteryModalOpen(false)}
                setBatteryHandler={setBatteryHandler}
            />
            <ComponentModal
                addComponent={addComponent}
                isOpen={isComponentCreationOpen}
                onClose={() => setIsComponentCreationOpen(false)}
            />
            <Page isLarge breadCrumbItems={breadCrumbItems} loading={loading}>
                <Stack p="4" bg={bgColor} rounded={'lg'} boxShadow={'lg'}>
                    <Text fontWeight="semibold">Create a satellite:</Text>
                    {error && (
                        <Alert status="error">
                            <AlertIcon />
                            {error}
                        </Alert>
                    )}
                    <Stack spacing={5} w={'full'}>
                        <FormControl id="satName">
                            <FormLabel>Satellite Name</FormLabel>
                            <Input
                                disabled={loading}
                                value={satName}
                                onChange={(event) => setSatName(event.target.value)}
                                placeholder="Satellite Name"
                                _placeholder={{ color: 'gray.500' }}
                                type="text"
                            />
                        </FormControl>
                        <FormControl id="tleURL">
                            <FormLabel>TLE Url</FormLabel>
                            <Input
                                disabled={loading}
                                value={tleUrl}
                                onChange={(event) => setTleUrl(event.target.value)}
                                placeholder="TLE Url"
                                _placeholder={{ color: 'gray.500' }}
                                type="url"
                            />
                        </FormControl>
                        <Divider />
                        <FormControl id="groundStations">
                            <FormLabel>Satellite Groundstations</FormLabel>
                            <AddGroundStation
                                isLoading={loading}
                                setSatelliteGroundStationIds={setSatelliteGroundStationIds}
                            />
                        </FormControl>
                        <Divider />
                        <FormControl id="satBattery">
                            <FormLabel>Satellite Battery</FormLabel>
                            <Box pb={4}>
                                {battery ? (
                                    <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(3, 1fr)" gap="4px">
                                        <Item name={'Capacity:'} value={battery.capacity.toString()} />
                                        <Item name={'Initial available charge:'} value={battery.initSocA.toString()} />
                                        <Item name={'Initial bound charge:'} value={battery.initSocB.toString()} />
                                        <Item name={'KiBaM c:'} value={battery.c.toString()} />
                                        <Item name={'KiBaM p:'} value={battery.p.toString()} />
                                    </Grid>
                                ) : (
                                    <HStack w="full" alignItems={'center'} justify="center">
                                        <Text>No Battery created.</Text>
                                    </HStack>
                                )}
                            </Box>
                            <Button w="full" isLoading={loading} onClick={() => setIsBatteryModalOpen(true)}>
                                Edit Battery
                            </Button>
                        </FormControl>
                        <Divider />
                        <FormControl id="satComponents">
                            <FormLabel>Satellite Components</FormLabel>
                            <ComponentTable components={components} setComponents={setComponents} />
                            <Button w="full" isLoading={loading} onClick={() => setIsComponentCreationOpen(true)}>
                                Add Component
                            </Button>
                        </FormControl>
                        <Stack spacing={6} pt={'4'} direction={['column', 'row']}>
                            <Button
                                onClick={() => handleSubmit()}
                                disabled={
                                    battery === null ||
                                    satName === '' ||
                                    tleUrl === '' ||
                                    satelliteGroundStationIds.length === 0 ||
                                    loading
                                }
                                isLoading={loading}
                                bg={'blue.400'}
                                color={'white'}
                                w="full"
                                _hover={{
                                    bg: 'blue.500',
                                }}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </Page>
        </>
    );
};

export default SatelliteCreatePage;

import { Box, Text } from '@chakra-ui/react';
import { BreadcrumbItemProps } from '../../components/Breadcrumb';
import CustomCard from '../../components/CustomCard';
import Page from '../../components/Page';
import config from '../../Config';
import MetricsInfo from './MetricsInfo';

const breadCrumbItems: BreadcrumbItemProps[] = [
    {
        name: 'Home',
        to: config.REALTIME_PREFIX,
    },
];

const HomePage = () => {
    return (
        <Page isLarge breadCrumbItems={breadCrumbItems}>
            <CustomCard name={'Welcome'}>
                <Box>
                    <Text>Welcome to the 🛰️SatSim🛰️ frontend.</Text>
                </Box>
            </CustomCard>
            <CustomCard name={'Metrics'}>
                <MetricsInfo />
            </CustomCard>
        </Page>
    );
};

export default HomePage;

import { PlusSquareIcon } from '@chakra-ui/icons';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    VStack,
} from '@chakra-ui/react';
import { JsonInput } from '@mantine/core';
import { useCallback, useRef, useState } from 'react';
import { useSatDetailContext } from '../../../context/SatelliteDetailContext';

const UploadOperation = () => {
    const { uploadOperation } = useSatDetailContext();
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);
    const [operation, setOperation] = useState('');
    const [error, setError] = useState('');
    const cancelRef = useRef(null);

    const upload = useCallback(
        async (operationRawText: string) => {
            const error = await uploadOperation(operationRawText);
            if (error === '') {
                setIsOpen(true);
            }
            setError(error);
        },
        [uploadOperation]
    );

    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Upload successful</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>The upload of the given operation was successful.</AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Close
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <VStack align="start">
                <Button
                    leftIcon={<PlusSquareIcon />}
                    bg={'blue.400'}
                    color={'white'}
                    _hover={{
                        bg: 'blue.500',
                    }}
                    onClick={() => {
                        upload(operation);
                    }}
                    disabled={operation === ''}
                >
                    Upload
                </Button>
                <Box w={'100%'}>
                    <JsonInput
                        label="Your operation"
                        placeholder="Insert the next operation for the simulation"
                        validationError="Invalid Operation"
                        error={error}
                        formatOnBlur
                        autosize
                        minRows={12}
                        value={operation}
                        onChange={setOperation}
                    />
                </Box>
            </VStack>
        </>
    );
};

export default UploadOperation;

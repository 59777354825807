import { createContext, useContext } from 'react';
import { Operation, Satellite } from '../types/Satellite';

type ContextType = {
    satellite: Satellite,
    futureOperation: Operation | null,
    deleteOperation: (id: string) => Promise<void>,
    uploadOperation: (operationRawText: string) => Promise<string>,
};

export const SatDetailContext = createContext<ContextType>({
    satellite: {
        components: [],
        groundStationAccessIntervals: [],
        satelliteName: "TEST",
        tle: "",
        tleURL: "",
        sunAccessIntervals: [],
        satelliteOperation: null,
        groundStations: [],
        id: 50,
    },
    futureOperation: null,
    deleteOperation: async (id: string) => {},
    uploadOperation: async (operationRawText: string) => {return ''},
});

export const useSatDetailContext = () => useContext(SatDetailContext);

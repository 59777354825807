import { createContext, useContext } from 'react';
import { Simulation } from '../types/Simulation';

type ContextType = {
    storedSimulations: Simulation[];
    setStoredSimulations: (
        value: Simulation[] | ((val: Simulation[]) => Simulation[])
    ) => void;
};

export const SimulationContext = createContext<ContextType>({
    storedSimulations: [],
    setStoredSimulations: () => {},
});

export const useSimulationContext = () => useContext(SimulationContext);

import { Box, Spinner, VStack } from '@chakra-ui/react';
import { MultiValue, Select } from 'chakra-react-select';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import GroundStation from '../../../types/GroundStation';

type option = {
    value: string;
    label: string;
};

interface Props {
    isLoading: boolean;
    setSatelliteGroundStationIds: React.Dispatch<
        React.SetStateAction<number[]>
    >;
}

const GroundStationOverview = ({
    isLoading,
    setSatelliteGroundStationIds,
}: Props) => {
    const { axiosInstance } = useAuth();
    const [loading, setLoading] = useState(false);
    const [groundStations, setGroundStations] = useState<GroundStation[]>([]);

    const handleChange = (selected: MultiValue<option>) => {
        const groundStationIds: number[] = [];
        selected.forEach((sel) => {
            groundStationIds.push(Number.parseInt(sel.value));
        });
        setSatelliteGroundStationIds(groundStationIds);
    };

    const groundStationsLabels: option[] = useMemo(() => {
        const options: option[] = [];
        groundStations.forEach((gs) => {
            options.push({
                label: `${gs.groundStationName} (${gs.id!})`,
                value: gs.id!.toString(),
            });
        });
        return options;
    }, [groundStations]);

    useEffect(() => {
        const loadGroundStations = async () => {
            setLoading(true);
            const groundStations = await axiosInstance.get<GroundStation[]>('/api/groundstations');
            setGroundStations(groundStations.data);
            setLoading(false);
        };
        loadGroundStations();
    }, [axiosInstance, setLoading]);

    return (
        <Box>
            {loading ? (
                <VStack>
                    <Spinner />
                </VStack>
            ) : (
                <Select
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    placeholder="Select satellite groundstations..."
                    isMulti
                    options={groundStationsLabels}
                    onChange={handleChange}
                />
            )}
        </Box>
    );
};

export default GroundStationOverview;

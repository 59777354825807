import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export interface PrivateRouteProps extends RouteProps {
    redirectPath: string;
}

const PrivateRoute = ({ redirectPath, ...props }: PrivateRouteProps) => {
    const { isAuthenticated } = useAuth();
    if (!isAuthenticated) {
        return <Redirect to={redirectPath} />;
    }
    return <Route {...props} />;
};

export default PrivateRoute;

import * as Yup from 'yup';

const BatterySchema = Yup.object().shape({
    capacity: Yup.number()
        .min(0, 'Must be >= 0.')
        .required('Capacity is required.')
        .test(
            'capacity-at-least-a+b',
            'Capacity must be at least the sum of available and bound charge.',
            (value, context) => {
                if (
                    context.parent.initSocA + context.parent.initSocB <=
                    (value ?? 0)
                )
                    return true;
                else return false;
            }
        ),
    initSocA: Yup.number()
        .min(0, 'Must be >= 0.')
        .required('InitSocA is required.')
        .test(
            'initSoCA-max-capacity*c',
            'Initial available charge can be a maximum of Capacity * C.',
            (value, context) => {
                if (context.parent.c * context.parent.capacity >= (value ?? 0))
                    return true;
                else return false;
            }
        ),
    initSocB: Yup.number()
        .min(0, 'Must be >= 0.')
        .required('InitSocB is required.')
        .test(
            'initSoCA-max-capacity*(1-c)',
            'Initial available charge can be a maximum of Capacity * (1-C).',
            (value, context) => {
                if (
                    (1 - context.parent.c) * context.parent.capacity >=
                    (value ?? 0)
                )
                    return true;
                else return false;
            }
        ),
    c: Yup.number()
        .min(0, 'Must be >= 0.')
        .max(1, 'Must be <= 1.')
        .required('KiBaM c is required.'),
    p: Yup.number()
        .min(0, 'Must be >= 0.')
        .max(1, 'Must be <= 1.')
        .required('KiBaM p is required.'),
});

export default BatterySchema;

import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbItemProps } from '../../components/Breadcrumb';
import Page from '../../components/Page';
import RefreshButton from '../../components/RefreshButton';
import config from '../../Config';
import { useAuth } from '../../context/AuthContext';
import GroundStation from '../../types/GroundStation';

const breadCrumbItems: BreadcrumbItemProps[] = [
    {
        name: 'Home',
        to: config.REALTIME_PREFIX,
    },
    {
        name: 'Groundstation',
        to: `${config.REALTIME_PREFIX}/groundstations`,
    },
];

const GroundStationOverviewPage = () => {
    const { axiosInstance } = useAuth();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [groundstations, setGroundStations] = useState<GroundStation[]>([]);
    const bgColor = useColorModeValue('white', 'gray.700');

    const getGroundstations = useCallback(async () => {
        try {
            setLoading(true);
            const groundStations = await axiosInstance.get<GroundStation[]>('/api/groundstations');
            setGroundStations(groundStations.data);
            setLoading(false);
        } catch (err) {
            console.error('Could not load Groundstations from API.');
        }
    }, [axiosInstance]);

    const deleteGroundstation = useCallback(async (id: number, name: string) => {
        if (!window.confirm(`Do you really want to delete the groundstation ${name}?`)) return;
        try {
            setLoading(true);
            await axiosInstance.delete(`/api/groundstations/${id}`);
            getGroundstations()
        } catch (err) {
            console.error('Could not delete Groundstations from API.');
            setLoading(false);
        }
    }, [axiosInstance, getGroundstations]);


    useEffect(() => {
        getGroundstations();
    }, [getGroundstations]);

    return (
        <Page breadCrumbItems={breadCrumbItems} isLarge>
            <Stack p="4" bg={bgColor} rounded={'lg'} boxShadow={'lg'}>
                <HStack justifyContent={'space-between'}>
                    <Text fontWeight="semibold">All groundstations:</Text>
                    <HStack justifyContent={'space-between'}>
                        <Button
                            leftIcon={<AddIcon />}
                            bg={'green.400'}
                            color={'white'}
                            _hover={{
                                bg: 'green.500',
                            }}
                            isLoading={loading}
                            onClick={() => history.push(`${config.REALTIME_PREFIX}/groundstations/create`)}
                        >
                            Create Groundstation
                        </Button>
                        <RefreshButton
                            onClick={() => getGroundstations()}
                            loading={loading}
                        />
                    </HStack>
                </HStack>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Name</Th>
                            <Th isNumeric>Latitude</Th>
                            <Th isNumeric>Longitude</Th>
                            <Th isNumeric>Altitude</Th>
                            <Th isNumeric>Detector elevation (Orekit)</Th>
                            <Th isNumeric>Detector maxcheck (Orekit)</Th>
                            <Th isNumeric>Detector treshold (Orekit)</Th>
                            <Th isNumeric>Satellite Count</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {groundstations.map((gs) => (
                            <Tr
                                key={gs.groundStationName}
                                _hover={{
                                    bg: 'blackAlpha.200',
                                }}
                            >
                                <Td>{gs.id!}</Td>
                                <Td>{gs.groundStationName}</Td>
                                <Td isNumeric>{gs.latitude}</Td>
                                <Td isNumeric>{gs.longitude}</Td>
                                <Td isNumeric>{gs.altitude}</Td>
                                <Td isNumeric>{gs.elevation}</Td>
                                <Td isNumeric>{gs.maxcheck}</Td>
                                <Td isNumeric>{gs.threshold}</Td>
                                <Td isNumeric>{gs.satellites?.length}</Td>
                                <Td isNumeric>
                                    <IconButton
                                        colorScheme={"red"}
                                        isDisabled={gs.satellites && gs.satellites.length > 0}
                                        aria-label={`Delete Groundstation with ID ${gs.id!}`}
                                        icon={<DeleteIcon />}
                                        onClick={() => deleteGroundstation(gs.id!, gs.groundStationName)}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Stack>
        </Page>
    );
}

export default GroundStationOverviewPage;

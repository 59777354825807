import { ChevronDownIcon } from '@chakra-ui/icons';
import { Checkbox, HStack, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FixedSizeGrid as Grid } from 'react-window';
import Datapoint from '../types/Datapoint';
import { createTimeString } from '../utils/createTimeString';

type Props = {
    dataPoints: Datapoint[];
};

const DatapointTable = ({ dataPoints }: Props) => {
    const [parseDate, setParseDate] = useState(false);
    const [width, setWidth] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const el = ref.current;
        if (!el) return;

        function handleResize() {
            if (!el) return;
            const { width } = el.getBoundingClientRect();
            setWidth(width);
        }

        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(el);

        return () => resizeObserver.disconnect();
    }, []);

    const download = (filename: string, text: string) => {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    const convertJsonToCSV = (): string => {
        if (!dataPoints.every((p) => typeof p === 'object' && p !== null)) {
            return '';
        }
        const heading = Object.keys(dataPoints[0]).join(',');
        const body = dataPoints.map((j) => Object.values(j).join(',')).join('\n');
        return heading + '\n' + body;
    };

    return (
        <Stack>
            <HStack spacing="10" justify="space-between" mb="7">
                <Checkbox checked={parseDate} onChange={(event) => setParseDate(event.target.checked)}>
                    Parse Date
                </Checkbox>
                <Menu>
                    <MenuButton
                        px={4}
                        py={2}
                        transition="all 0.2s"
                        borderRadius="md"
                        borderWidth="1px"
                        _expanded={{ bg: 'blue.400' }}
                        _focus={{ boxShadow: 'outline' }}
                    >
                        Download <ChevronDownIcon />
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => download('summary.json', JSON.stringify(dataPoints, null, 2))}>
                            as JSON
                        </MenuItem>
                        <MenuItem onClick={() => download('summary.csv', convertJsonToCSV())}>as CSV</MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
            <HStack gap={0} spacing={0} fontWeight={'bold'}>
                <Text w={width / 4}>TIME</Text>
                <Text w={width / 4}>LOAD</Text>
                <Text w={width / 4}>AVAILABLE CHARGE</Text>
                <Text w={width / 4}>BOUND CHARGE</Text>
            </HStack>
            <div ref={ref}>
                <Grid
                    height={400}
                    width={width}
                    rowCount={dataPoints.length}
                    rowHeight={35}
                    columnCount={4}
                    columnWidth={width / 4}
                >
                    {({
                        columnIndex,
                        rowIndex,
                        style,
                    }: {
                        columnIndex: number;
                        rowIndex: number;
                        style: React.CSSProperties;
                    }) => {
                        const dataPoint = dataPoints[rowIndex];

                        return (
                            <div style={style}>
                                {columnIndex === 0 && (
                                    <Text>
                                        {parseDate ? createTimeString(new Date(dataPoint.time * 1000)) : dataPoint.time}
                                    </Text>
                                )}
                                {columnIndex === 1 && (
                                    <Text color={dataPoint.load > 0 ? 'red' : 'green'}>{dataPoint.load}</Text>
                                )}
                                {columnIndex === 2 && (
                                    <Text>
                                        {dataPoint.available_charge}
                                    </Text>
                                )}
                                {columnIndex === 3 && (
                                    <Text>{dataPoint.bound_charge}</Text>
                                )}
                            </div>
                        );
                    }}
                </Grid>
            </div>
        </Stack>
    );
};

export default DatapointTable;

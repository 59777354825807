import { HStack, Text, VStack } from '@chakra-ui/layout';
import { useDate } from '../../hooks/useDate';

const TimeInfo = () => {
    const { date: dateLocal, time: timeLocal } = useDate({ type: 'LOCAL' });
    const { date: dateUtc, time: timeUtc } = useDate({ type: 'UTC' });
    return (
        <VStack spacing="0" fontSize="small">
            <HStack justifyContent="space-between" w="100%">
                <Text fontWeight="semibold">UTC:</Text>
                <Text>{`${dateUtc} - ${timeUtc}`}</Text>
            </HStack>
            <HStack justifyContent="space-between" w="100%">
                <Text fontWeight="semibold">Local:</Text>
                <Text>{`${dateLocal} - ${timeLocal}`}</Text>
            </HStack>
        </VStack>
    );
};

export default TimeInfo;

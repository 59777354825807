import { Stack, Text, useColorModeValue } from '@chakra-ui/react';

interface Props {
    name?: string;
    children?: React.ReactNode;
}

const CustomCard = ({ name, children }: Props) => {
    const bgColor = useColorModeValue('white', 'gray.700');
    return (
        <Stack p="4" bg={bgColor} rounded={'lg'} boxShadow={'lg'}>
            {name && <Text fontWeight="semibold" fontSize="2xl">{name}</Text>}
            {children}
        </Stack>
    );
};

export default CustomCard;

import { Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BreadcrumbItemProps } from '../../components/Breadcrumb';
import CustomCard from '../../components/CustomCard';
import Page from '../../components/Page';
import RefreshButton from '../../components/RefreshButton';
import config from '../../Config';
import { useAuth } from '../../context/AuthContext';
import { SatDetailContext } from '../../context/SatelliteDetailContext';
import { useSettings } from '../../context/SettingsContext';
import { Operation, Satellite } from '../../types/Satellite';
import DebugActions from './DebugActions';
import { SatelliteInfo } from './Info/SatelliteInfo';
import OperationsCardContent from './Operations/OperationsCardContent';
import SatelliteLocation from './SatelliteLocation';
import TelemetryInfo from './Telemetry/TelemetryInfo';

interface ParamTypes {
    satName: string;
}

const SatelliteDetailPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [satellite, setSatellite] = useState<Satellite | null>(null);
    const [futureOperation, setFutureOperation] = useState<Operation | null>(null);
    const { satName } = useParams<ParamTypes>();
    const { axiosInstance } = useAuth();
    const { debug } = useSettings();
    const history = useHistory();

    const breadCrumbItems: BreadcrumbItemProps[] = useMemo(() => {
        return [
            {
                name: 'Home',
                to: config.REALTIME_PREFIX,
            },
            {
                name: 'Satellites',
                to: `${config.REALTIME_PREFIX}/satellites`,
            },
            {
                name: satName,
                to: `${config.REALTIME_PREFIX}/satellites/detail/${satName}`,
            },
        ];
    }, [satName]);

    const loadOperations = useCallback(async () => {
        try {
            const backendFutureOperation = await axiosInstance.get<
                Operation
            >(`/api/operations/${satName}`);
            setFutureOperation(backendFutureOperation.data);
        } catch (err: any) {
            if(err.status === 404) {
                setFutureOperation(null);
            } else {
                console.error('Could not load satellite from API.');
                history.push(`${config.REALTIME_PREFIX}/satellites`);
            }
            
        }
    }, [satName, axiosInstance, history]);

    const getSatellite = useCallback(async () => {
        try {
            const satellites = await axiosInstance.get<Satellite>(
                `/api/satellites/${satName}`
            );
            setSatellite(satellites.data);
        } catch (err) {
            console.error('Could not load satellite from API.');
            history.push(`${config.REALTIME_PREFIX}/satellites`);
        }
    }, [axiosInstance, satName, history]);

    const refresh = useCallback(async () => {
        setLoading(true);
        loadOperations();
        getSatellite();
        setLoading(false);
    }, [getSatellite, loadOperations]);

    const uploadOperation = useCallback(
        async (operationRawText: string): Promise<string> => {
            try {
                await axiosInstance.post(
                    `/api/operations/${satName}/upload`,
                    operationRawText
                );
                await refresh();
                return '';
            } catch (error) {
                console.error(error);
                return 'Could not upload operation.';
            }
        },
        [refresh, satName, axiosInstance]
    );

    const deleteOperation = useCallback(
        async (id: string) => {
            try {
                await axiosInstance.delete(`/api/operations/${satName}/${id}`);
                await refresh();
            } catch (err) {
                console.error(err);
            }
        },
        [refresh, satName, axiosInstance]
    );

    useEffect(() => {
        refresh();
    }, [refresh]);

    if (!satellite) {
        return (
            <Page breadCrumbItems={breadCrumbItems} loading={loading}>
                <RefreshButton onClick={() => refresh()} loading={loading} />
                <Text fontWeight="semibold">Loading the 🛰️...</Text>;
            </Page>
        );
    }

    return (
        <Page breadCrumbItems={breadCrumbItems} loading={loading}>
            <SatDetailContext.Provider
                value={{
                    futureOperation,
                    satellite,
                    deleteOperation,
                    uploadOperation,
                }}
            >
                <RefreshButton onClick={() => refresh()} loading={loading} />
                <CustomCard name={`🛰️ ${satellite.satelliteName}`}>
                    <SatelliteInfo />
                </CustomCard>
                {debug && (
                    <CustomCard name={'Danger Zone'}>
                        <DebugActions />
                    </CustomCard>
                )}
                <CustomCard name={'Telemetry'}>
                    <TelemetryInfo />
                </CustomCard>
                <CustomCard name={'Operations'}>
                    <OperationsCardContent />
                </CustomCard>
                <CustomCard name={'Location'}>
                    <SatelliteLocation tle={satellite.tle} />
                </CustomCard>
            </SatDetailContext.Provider>
        </Page>
    );
};

export default SatelliteDetailPage;

import { Flex, Switch, SwitchProps, Text, Tooltip } from '@chakra-ui/react';

interface SwitchWithTextProps extends SwitchProps {
    text: string;
    tooltip?: string;
}
const SwitchWithText = ({ text, tooltip, ...rest }: SwitchWithTextProps) => (
    <Flex gap={'16px'}>
        <Switch size="lg" {...rest} />
        {tooltip ? (
            <Tooltip label={tooltip}>
                <Text>{text}</Text>
            </Tooltip>
        ) : (
            <Text>{text}</Text>
        )}
    </Flex>
);

export default SwitchWithText;

import { Box, HStack, Stack } from '@chakra-ui/react';
import { useSatDetailContext } from '../../../context/SatelliteDetailContext';
import { useSettings } from '../../../context/SettingsContext';
import { isTimeInIntervals } from '../../../utils/isActualTimeInIntervals';
import SatelliteInfoAccordion from './SatelliteInfoAccordion';

interface ItemProps {
    name: String;
    value: String;
}
const Item = ({ name, value }: ItemProps) => {
    return (
        <HStack>
            <Box fontWeight="semibold">{name}</Box>
            <Box fontSize={'small'}>{value}</Box>
        </HStack>
    );
};

export const SatelliteInfo = () => {
    const { debug } = useSettings();
    const { satellite } = useSatDetailContext();
    return (
        <Stack flexDir={{ base: 'column' }} justifyContent="space-between">
            <Stack pl={{base: '7', sm: '0'}} >
                <Item name={'TLE:'} value={satellite.tle} />
                <HStack spacing={{base: '0', sm: '10'}} flexDir={{ base: 'column', sm: 'row' }} align={{base: 'flex-start', sm: 'flex-end'}}>
                    
                    {debug && (
                        <Item
                            name={'Sun in sight:'}
                            value={isTimeInIntervals(Date.now() / 1000, satellite.sunAccessIntervals) ? '✔️' : '❌'}
                        />
                    )}
                    <Item
                        name={'Contact to groundstation:'}
                        value={isTimeInIntervals(Date.now() / 1000, satellite.groundStationAccessIntervals) ? '✔️' : '❌'}
                    />
                    <Item
                        name={'Components:'}
                        value={satellite.components.length.toFixed(0)}
                    />
                </HStack>
            </Stack>
            <SatelliteInfoAccordion />
        </Stack>
    );
};

export default SatelliteInfo;

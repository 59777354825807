import {
    Button,
    Flex,
    Grid,
    GridItem,
    GridItemProps,
    HStack,
    Stack,
    Text,
    Tooltip,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import CustomCard from '../../../components/CustomCard';
import { useSimulationCreateContext } from '../../../context/SimulationCreateContext';
import { SimulationSatellite } from '../../../types/Simulation';
import LoadRealtimeSatelliteModal from './LoadRealtimeSatelliteModal';

type Props = {};

interface ItemProps extends GridItemProps {
    name: String;
    value: String;
}
const Item = ({ name, value, ...rest }: ItemProps) => {
    return (
        <GridItem {...rest}>
            <HStack>
                <Text as="h4" fontWeight="semibold" fontSize="md">
                    {name}
                </Text>
                <Text fontSize="small">{value}</Text>
            </HStack>
        </GridItem>
    );
};

export const SatellitePreview = ({ sat }: { sat: SimulationSatellite | null }) => {
    const secondaryTextColor = useColorModeValue('gray.600', 'gray.400');

    if (sat === null) {
        return (
            <Flex align="center" justify={{ base: 'center', md: 'flex-start' }}>
                <Text fontSize="md" fontWeight="medium" color={secondaryTextColor}>
                    No Satellite given.
                </Text>
            </Flex>
        );
    }

    return (
        <>
            <Text mt={'5px'} as="h3" fontWeight="semibold" fontSize="xl">
                Satellite data:
            </Text>
            <Grid
                templateRows={{ base: 'repeat(7, 1fr)', md: 'repeat(4, 1fr)' }}
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(3, 1fr)',
                }}
                gap="4px"
            >
                <Item colSpan={{ base: 1, md: 3 }} name={'Name:'} value={sat.name} />
                <Item colSpan={{ base: 1, md: 3 }} name={'TLE:'} value={sat.tle} />
                <Item name={'Capacity:'} value={sat.capacity.toString()} />
                <Item name={'Initial available charge:'} value={sat.initSocA.toString()} />
                <Item name={'Initial bound charge:'} value={sat.initSocB.toString()} />
                <Item name={'KiBaM C:'} value={sat.c.toString()} />
                <Item name={'KiBaM P:'} value={sat.p.toString()} />
            </Grid>
        </>
    );
};

const SimulationSatelliteTab = (props: Props) => {
    const { startDate, sat, setSat } = useSimulationCreateContext();
    const { isOpen: isLoadOpen, onOpen: onLoadOpen, onClose: onLoadClose } = useDisclosure();

    const setSatelliteHandler = async (satToAssign: SimulationSatellite): Promise<void> => {
        if (sat !== null && !window.confirm('Do you really want to override the old satellite?')) {
            return;
        }
        setSat(satToAssign);
    };

    return (
        <>
            <LoadRealtimeSatelliteModal
                isOpen={isLoadOpen}
                onClose={onLoadClose}
                handler={setSatelliteHandler}
                startTime={startDate}
            />
            <Stack pb={6}>
                <Text mt={'5px'} as="h2" fontWeight="semibold" fontSize="3xl">
                    Satellite
                </Text>
                <Text mb={'15px'} fontWeight="light" fontSize="lg" color={useColorModeValue('gray.600', 'gray.300')}>
                    The simulation requires a satellite to provide data for the calculations. You can either load a copy
                    of a satellite that is being tracked in realtime mode, or you can create a temporary satellite.
                </Text>
                <CustomCard>
                    <Flex gap="5" flexDir={{ base: 'column', md: 'row' }}>
                        <Tooltip
                            hasArrow
                            label="Load a copy from a realtime mode satellite."
                            placement="top"
                            fontSize="md"
                        >
                            <Button
                                w={{ base: '100%', md: '150px' }}
                                onClick={onLoadOpen}
                                disabled={startDate === null}
                            >
                                ↙️ Load
                            </Button>
                        </Tooltip>
                        <Tooltip hasArrow label="Clears the satellite field." placement="top" fontSize="md">
                            <Button w={{ base: '100%', md: '150px' }} onClick={() => setSat(null)}>
                                ❌ Remove
                            </Button>
                        </Tooltip>
                    </Flex>
                    <SatellitePreview sat={sat} />
                </CustomCard>
            </Stack>
        </>
    );
};

export default SimulationSatelliteTab;

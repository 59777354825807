import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSatDetailContext } from '../../../context/SatelliteDetailContext';
import { Operation } from '../../../types/Satellite';

interface Props {
    isDebug?: boolean;
    operation: Operation;
}

const chartOptions: ApexCharts.ApexOptions = {
    chart: {
        type: 'rangeBar',
    },
    plotOptions: {
        bar: {
            horizontal: true,
        },
    },
    xaxis: {
        type: 'datetime',
    },
};

const CurrentOperationInfo = ({ isDebug, operation }: Props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const theme = useColorModeValue('light', 'dark');
    const { deleteOperation } = useSatDetailContext();

    const chartSeries: ApexAxisChartSeries = useMemo(() => {
        const data: {
            x: string;
            y: number[];
        }[] = [];

        operation.componentActivations.forEach((el) => {
            data.push({
                x: el.id,
                y: [
                    el.activationPeriod.start * 1000,
                    el.activationPeriod.end * 1000,
                ],
            });
        });

        return [
            {
                data,
            },
        ];
    }, [operation]);

    return (
        <>
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                isCentered
                scrollBehavior={'inside'}
                size="6xl"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontWeight="semibold" fontSize="md">
                        Operation: {operation.id}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Chart
                            options={{...chartOptions, theme: { mode: theme}}}
                            series={chartSeries}
                            type="rangeBar"
                            width="100%"
                            height="100%"
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="red"
                            mr={3}
                            isDisabled={isDebug}
                            onClick={async () => {
                                await deleteOperation(operation.id);
                                setModalOpen(false);
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={() => setModalOpen(false)}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Stack
                shadow="md"
                bg={useColorModeValue('gray.100', 'gray.800')}
                borderRadius="md"
                p="2"
                spacing="1"
                cursor="pointer"
                _hover={{
                    bg: useColorModeValue('gray.200', 'gray.900'),
                }}
                onClick={() => setModalOpen(true)}
            >
                <HStack>
                    <Text fontWeight="semibold">ID:</Text>
                    <Text>{operation.id}</Text>
                </HStack>
                <HStack>
                    <Text fontWeight="semibold">Type:</Text>
                    <Text>{operation.type}</Text>
                </HStack>
            </Stack>
        </>
    );
};

export default CurrentOperationInfo;

import { createContext, useContext } from 'react';
import { AccessInterval } from '../types/Satellite';
import { SimulationGroundStation, SimulationSatellite } from '../types/Simulation';

type ContextType = {
    startDate: Date | null;
    endDate: Date | null;
    sat: SimulationSatellite | null;
    groundStation: SimulationGroundStation | null;
    passes: AccessInterval[];
    setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
    setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
    setSat: React.Dispatch<React.SetStateAction<SimulationSatellite | null>>;
    setGroundStation: React.Dispatch<React.SetStateAction<SimulationGroundStation | null>>;
    setPasses: React.Dispatch<React.SetStateAction<AccessInterval[]>>;
    hasError: () => boolean;
    isError: (name: string) => boolean;
    addError: (name: string) => void;
    removeError: (name: string) => void;
};

export const SimulationCreateContext = createContext<ContextType>({
    startDate: null,
    endDate: null,
    sat: null,
    groundStation: null,
    passes: [],
    setStartDate: () => {},
    setEndDate: () => {},
    setSat: () => {},
    setGroundStation: () => {},
    setPasses: () => {},
    hasError: () => false,
    isError: () => false,
    addError: () => {},
    removeError: () => {},
});

export const useSimulationCreateContext = () => useContext(SimulationCreateContext);

import { Simulation } from '../types/Simulation';

const generateAuthMessage = (token: string): string => {
    return JSON.stringify({
        type: 'AUTH',
        token: token,
    });
};

const generateHelloMessage = (simulation: Simulation): string => {
    return JSON.stringify({
        type: 'HELLO',
        sat_id: simulation.satellite.id,
        simulation_start: simulation.startDate,
        simulation_end: simulation.endDate,
        random_seed: simulation.seed === null ? undefined : simulation.seed,
        initial_available_charge: simulation.satellite.initSocA,
        initial_bound_charge: simulation.satellite.initSocB,
        include_slewing: simulation.includeSlewing,
    });
};

const generateOperationMessage = (operation: string): string => {
    const operationParsed = JSON.parse(operation);
    if (!operationParsed.operations || !operationParsed.operations[0]) return '';
    return JSON.stringify({
        type: 'OPERATION',
        operation: operationParsed.operations[0],
    });
};

const generateSkipMessage = () => {
    return JSON.stringify({
        type: 'SKIP',
    });
};

const generateHeartbeatMessage = () => {
    return JSON.stringify({
        type: 'HEARTBEAT',
    });
};

export {
    generateAuthMessage,
    generateHelloMessage,
    generateOperationMessage,
    generateSkipMessage,
    generateHeartbeatMessage,
};

import {
    Alert,
    AlertIcon,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import Component from '../../../types/Component';
import ComponentValidationSchema from './ComponentValidationSchema';

interface Props {
    addComponent: (comp: Component) => boolean;
    isOpen: boolean;
    onClose: () => void;
}

const ComponentModal = ({ addComponent, isOpen, onClose }: Props) => {
    const [error, setError] = useState(false);

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add a component</ModalHeader>
                <ModalCloseButton />
                <Formik
                    initialValues={{
                        name: '',
                        background: false,
                        slewingBefore: 0,
                        slewingAfter: 0,
                        powerConsumption: 0,
                    }}
                    validationSchema={ComponentValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        const wasCreated = addComponent(values);
                        if (wasCreated) {
                            setError(false);
                            onClose();
                        } else {
                            setError(true);
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <>
                            {error && (
                                <Alert status="error">
                                    <AlertIcon />
                                    There is already a component with this name.
                                </Alert>
                            )}
                            <Form onSubmit={handleSubmit}>
                                <ModalBody pb={6}>
                                    <Stack spacing={5} w={'full'}>
                                        <FormControl id="name">
                                            <FormLabel>Name [must be unique]</FormLabel>
                                            <Input
                                                name="name"
                                                id="name"
                                                placeholder="Name"
                                                _placeholder={{
                                                    color: 'gray.500',
                                                }}
                                                isInvalid={
                                                    touched.name &&
                                                    errors.name !== undefined
                                                }
                                                onChange={handleChange}
                                                value={values.name}
                                                type="text"
                                            />
                                            {touched.name && errors.name && (
                                                <Text color="red.500">
                                                    {errors.name}
                                                </Text>
                                            )}
                                        </FormControl>
                                        <FormControl id="background">
                                            <FormLabel>Background</FormLabel>
                                            <Checkbox
                                                name="background"
                                                id="background"
                                                isInvalid={
                                                    touched.background &&
                                                    errors.background !==
                                                    undefined
                                                }
                                                onChange={(event) => {
                                                    handleChange(event);
                                                    setFieldValue("slewingBefore", 0);
                                                    setFieldValue("slewingAfter", 0);
                                                }}
                                            >
                                                Component runs in background
                                            </Checkbox>
                                            {touched.background &&
                                                errors.background && (
                                                    <Text color="red.500">
                                                        {errors.background}
                                                    </Text>
                                                )}
                                        </FormControl>
                                        <FormControl id="slewingBefore">
                                            <FormLabel>
                                                Slewing before [min]
                                            </FormLabel>
                                            <Input
                                                name="slewingBefore"
                                                id="slewingBefore"
                                                placeholder="Slewing before"
                                                _placeholder={{
                                                    color: 'gray.500',
                                                }}
                                                isDisabled={
                                                    values.background
                                                }
                                                isInvalid={
                                                    touched.slewingBefore &&
                                                    errors.slewingBefore !==
                                                    undefined
                                                }
                                                onChange={handleChange}
                                                value={values.slewingBefore}
                                                type="number"
                                            />
                                            {touched.slewingBefore &&
                                                errors.slewingBefore && (
                                                    <Text color="red.500">
                                                        {errors.slewingBefore}
                                                    </Text>
                                                )}
                                        </FormControl>
                                        <FormControl id="slewingAfter">
                                            <FormLabel>Slewing after [min]</FormLabel>
                                            <Input
                                                name="slewingAfter"
                                                id="slewingAfter"
                                                placeholder="Slewing after"
                                                _placeholder={{
                                                    color: 'gray.500',
                                                }}
                                                isDisabled={
                                                    values.background
                                                }
                                                isInvalid={
                                                    touched.slewingAfter &&
                                                    errors.slewingAfter !==
                                                    undefined
                                                }
                                                onChange={handleChange}
                                                value={values.slewingAfter}
                                                type="number"
                                            />
                                            {touched.slewingAfter &&
                                                errors.slewingAfter && (
                                                    <Text color="red.500">
                                                        {errors.slewingAfter}
                                                    </Text>
                                                )}
                                        </FormControl>
                                        <FormControl id="powerConsumption">
                                            <FormLabel>
                                                Power consumption [mW]
                                            </FormLabel>
                                            <Input
                                                name="powerConsumption"
                                                id="powerConsumption"
                                                placeholder="Power consumption"
                                                _placeholder={{
                                                    color: 'gray.500',
                                                }}
                                                isInvalid={
                                                    touched.powerConsumption &&
                                                    errors.powerConsumption !==
                                                    undefined
                                                }
                                                onChange={handleChange}
                                                value={values.powerConsumption}
                                                type="number"
                                            />
                                            {touched.powerConsumption &&
                                                errors.powerConsumption && (
                                                    <Text color="red.500">
                                                        {
                                                            errors.powerConsumption
                                                        }
                                                    </Text>
                                                )}
                                        </FormControl>
                                    </Stack>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        colorScheme="blue"
                                        mr={3}
                                    >
                                        Save
                                    </Button>
                                    <Button onClick={onClose}>Cancel</Button>
                                </ModalFooter>
                            </Form>
                        </>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default ComponentModal;

import { Box, Flex, HStack, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { Link as LinkRouter } from "react-router-dom";
import { useSettings } from '../../context/SettingsContext';
import LogoutButton from '../RealTimeNav/LogoutButton';
import TimeInfo from '../RealTimeNav/TimeInfo';

const GeneralNav = () => {
    const { showTimeInfo } = useSettings();

    return (
        <Flex
            bg={useColorModeValue('white', 'gray.800')}
            color={useColorModeValue('gray.600', 'white')}
            minH={'60px'}
            py={{ base: 2 }}
            px={{ base: 4 }}
            borderBottom={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.900')}
            align={'center'}
            justify={'space-between'}
        >
            <Flex
                justify={{ base: 'center', md: 'start' }}
                alignItems={'center'}
            >
                <LinkRouter to="/">
                    <Box cursor={'pointer'}>
                        <Text
                            textAlign={useBreakpointValue({
                                base: 'center',
                                md: 'left',
                            })}
                            fontWeight={'bold'}
                            fontFamily={'heading'}
                            color={useColorModeValue('gray.800', 'white')}
                        >
                            🛰️ SatSim
                        </Text>
                    </Box>
                </LinkRouter>
            </Flex>
            <HStack spacing="5">´
                {showTimeInfo && <TimeInfo />}
                <LogoutButton />
            </HStack>
        </Flex>
    );
}

export default GeneralNav;
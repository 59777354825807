import { Heading, Stack, Text } from '@chakra-ui/react';
import { useSatDetailContext } from '../../../context/SatelliteDetailContext';
import { useSettings } from '../../../context/SettingsContext';
import { Operation } from '../../../types/Satellite';
import CurrentOperationInfo from './CurrentOperationInfo';

type OperationListProps = {
    heading: string;
    isDebug?: boolean;
    operation: Operation | null;
};
const OperationsList = ({ isDebug, heading, operation }: OperationListProps) => {
    return (
        <Stack spacing={'1'}>
            <Heading size="xs">{heading}</Heading>
            <Stack>
                {operation === null ? <Text>None</Text> : (
                    <CurrentOperationInfo
                        key={operation.id}
                        isDebug={isDebug}
                        operation={operation}
                    />
                )}
            </Stack>
        </Stack>
    );
};

const CurrentOperations = () => {
    const { satellite, futureOperation } = useSatDetailContext();
    const { debug } = useSettings();

    return (
        <Stack spacing="6">
            <OperationsList
                heading={'Operation that will be uploaded:'}
                operation={futureOperation}
            />
            {debug && (
                <OperationsList
                    heading={'[DEBUG]: Active operations on satellite:'}
                    isDebug={true}
                    operation={satellite.satelliteOperation}
                />
            )}
        </Stack>
    );
};

export default CurrentOperations;

import { AddIcon } from '@chakra-ui/icons';
import {
    Button,
    HStack,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbItemProps } from '../../components/Breadcrumb';
import Page from '../../components/Page';
import RefreshButton from '../../components/RefreshButton';
import config from '../../Config';
import { useAuth } from '../../context/AuthContext';
import { Satellite } from '../../types/Satellite';

const breadCrumbItems: BreadcrumbItemProps[] = [
    {
        name: 'Home',
        to: config.REALTIME_PREFIX,
    },
    {
        name: 'Satellites',
        to: `${config.REALTIME_PREFIX}/satellites`,
    },
];

const SatelliteOverviewPage = () => {
    const { axiosInstance } = useAuth();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [satellites, setSatellites] = useState<Satellite[]>([]);
    const bgColor = useColorModeValue('white', 'gray.700');

    const getSatellites = useCallback(async () => {
        try {
            setLoading(true);
            const satellites = await axiosInstance.get<Satellite[]>(
                '/api/satellites'
            );
            setSatellites(satellites.data);
            setLoading(false);
        } catch (err) {
            console.error('Could not load satellites from API.');
        }
    }, [axiosInstance]);

    useEffect(() => {
        getSatellites();
    }, [getSatellites]);

    return (
        <Page isLarge breadCrumbItems={breadCrumbItems} loading={loading}>
            <Stack p="4" bg={bgColor} rounded={'lg'} boxShadow={'lg'}>
                <HStack justifyContent={'space-between'}>
                    <Text fontWeight="semibold">Select a satellite:</Text>
                    <HStack justifyContent={'space-between'}>
                        <Button
                            leftIcon={<AddIcon />}
                            bg={'green.400'}
                            color={'white'}
                            _hover={{
                                bg: 'green.500',
                            }}
                            isLoading={loading}
                            onClick={() => history.push(`${config.REALTIME_PREFIX}/satellites/create`)}
                        >
                            Create Satellite
                        </Button>
                        <RefreshButton
                            onClick={() => getSatellites()}
                            loading={loading}
                        />
                    </HStack>
                </HStack>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>Type</Th>
                            <Th>Name</Th>
                            <Th isNumeric>Components</Th>
                            <Th isNumeric>Operation</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {satellites.map((sat) => (
                            <Tr
                                key={sat.satelliteName}
                                onClick={() => {
                                    history.push(`${config.REALTIME_PREFIX}/satellites/detail/${sat.satelliteName}`);
                                }}
                                cursor={'pointer'}
                                _hover={{
                                    bg: 'blackAlpha.200',
                                }}
                            >
                                <Td>🛰️</Td>
                                <Td>{sat.satelliteName}</Td>
                                <Td isNumeric>{sat.components.length}</Td>
                                <Td isNumeric>
                                    {sat.satelliteOperation ? sat.satelliteOperation.id : "None"}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Stack>
        </Page>
    );
};

export default SatelliteOverviewPage;

import { Box, useColorModeValue } from '@chakra-ui/react';
import * as echarts from 'echarts';
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useSatDetailContext } from '../../../context/SatelliteDetailContext';
import { useTelemetryContext } from '../../../context/TelemetryContext';
import BatteryLoad from '../../../types/BatteryLoad';
import { createDateString } from '../../../utils/createDateString';

interface LoadData {
    name: Date;
    value: [Date, number]
}

const TelemetryLoadTrace = () => {
    const theme = useColorModeValue('light', 'dark');
    const { axiosInstance } = useAuth();
    const { satellite } = useSatDetailContext();
    const { includeFuture, startDate, endDate } = useTelemetryContext();
    const [chartSeries, setChartSeries] = useState<LoadData[]>([]);

    const loadTraceLoad = useCallback(async () => {
        if (!startDate || !endDate) {
            setChartSeries([]);
            return;
        }
        try {
            const apiData = await axiosInstance.get<BatteryLoad[]>(
                `/api/telemetry/${satellite.satelliteName
                }/download/loadtrace/json/${createDateString(
                    startDate
                )}/${createDateString(endDate)}`,
                {
                    params: {
                        future: includeFuture,
                    },
                }
            );
            const series: LoadData[] = [];
            apiData.data.forEach((load) => {
                const date = echarts.number.parseDate(load.timestamp * 1000);
                series.push({
                    name: date,
                    value: [
                        date,
                        load.load ?? 0
                    ]
                });
            })
            setChartSeries(series);
        } catch (err) {
            console.error(err);
            setChartSeries([]);
        }
    }, [axiosInstance, satellite, startDate, endDate, includeFuture]);

    useEffect(() => {
        loadTraceLoad();
    }, [loadTraceLoad]);

    return (
        <Box h={"500px"}>
            <ReactEcharts
                theme={theme}
                style={{
                    height: 500
                }}
                option={{
                    useUTC: true,
                    title: {
                        left: 'center',
                        text: "Batteryload"
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        top: 70,
                        left: 90,
                        right: 60,
                        height: 350
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            throttle: 50
                        }
                    ],
                    toolbox: {
                        left: 'center',
                        top: 30,
                        itemSize: 20,
                        feature: {
                            saveAsImage: {},
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                        },
                    },
                    visualMap: {
                        left: 'right',
                        min: 0,
                        max: 1,
                        inRange: {
                            color: ['green', 'red']
                        },
                    },
                    xAxis: {
                        type: "time",
                    },
                    yAxis: {
                        type: "value",
                        name: "in mJ"
                    },
                    series: [{
                        name: "Batteryload",
                        data: chartSeries,
                        type: "line",
                        step: 'start',
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(58,77,233,0.8)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(58,77,233,0.3)'
                                }
                            ])
                        },
                    }]
                }}
            />
        </Box>
    );
};

export default TelemetryLoadTrace;

import { ReactNode } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { SettingsContext } from './SettingsContext';
interface Props {
    children?: ReactNode;
}

const SettingsProvider = ({ children }: Props) => {
    const [debug, setDebug] = useLocalStorage('sat-sim-debug', false);
    const [initialShowSatLocation, setInitialShowSatLocation] = useLocalStorage(
        'sat-sim-initialShowSatLocation',
        false
    );
    const [showTimeInfo, setShowTimeInfo] = useLocalStorage(
        'sat-sim-showTimeInfo',
        true
    );

    return (
        <SettingsContext.Provider
            value={{
                debug,
                setDebug,
                initialShowSatLocation,
                setInitialShowSatLocation,
                showTimeInfo,
                setShowTimeInfo,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsProvider;

import { useMemo } from 'react'
import { AccessInterval } from '../types/Satellite';
import Chart from 'react-apexcharts';
import { useColorModeValue } from '@chakra-ui/react';

interface Props {
    intervals: AccessInterval[];
}

const chartOptions: ApexCharts.ApexOptions = {
    chart: {
        type: 'rangeBar',
    },
    plotOptions: {
        bar: {
            horizontal: true,
        },
    },
    xaxis: {
        type: 'datetime',
    },
};

const IntervalGraph = ({ intervals }: Props) => {
    const theme = useColorModeValue('light', 'dark');
    const chartSeries: ApexAxisChartSeries = useMemo(() => {
        const data: {
            x: string;
            y: number[];
        }[] = [];

        intervals.forEach((el) => {
            data.push({
                x: 'Loading',
                y: [
                    el.start * 1000,
                    el.end * 1000,
                ],
            });
        });

        return [
            {
                data,
            },
        ];
    }, [intervals]);

    return (
        <Chart
            options={{...chartOptions, theme: { mode: theme}}}
            series={chartSeries}
            type="rangeBar"
            width="100%"
            height="300px"
        />
    )
}

export default IntervalGraph

import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { BreadcrumbItemProps } from '../../components/Breadcrumb';
import Page from '../../components/Page';
import config from '../../Config';
import { useSimulationCreateContext } from '../../context/SimulationCreateContext';
import SimulationCreateFinalTab from './SimulationCreateFinalTab';
import SimulationSatelliteTab from './SimulationSatelliteTab';
import SimulationTimesTab from './SimulationTimesTab';

const PREFIX = config.SIMULATOR_PREFIX;
const breadCrumbItems: BreadcrumbItemProps[] = [
    {
        name: 'Home',
        to: `${PREFIX}/`,
    },
    {
        name: 'Create',
        to: `${PREFIX}/create`,
    },
];

const SimulationCreatePage = () => {
    const [step, setStep] = useState<number>(1);
    const { hasError, sat, setSat } = useSimulationCreateContext();
    const goStepBack = useCallback(() => {
        if (step <= 1) return;
        if (!window.confirm("You will lose this step's data if you go back. Are you sure?")) return;
        if (step < 3) {
            setSat(null);
        }
        setStep((oldVal) => Math.max(oldVal - 1, 1));
    }, [step, setStep, setSat]);

    const goStepForward = useCallback(() => {
        if (step >= 3) return;
        if (hasError()) return;
        if (step === 2 && sat === null) return;
        setStep((oldVal) => Math.min(oldVal + 1, 3));
    }, [step, setStep, hasError, sat]);

    return (
        <Page breadCrumbItems={breadCrumbItems} title="Create a Simulation">
            <Flex flexDir="column" h={'100%'}>
                <Box flexGrow={1}>
                    {step === 1 && <SimulationTimesTab />}
                    {step === 2 && <SimulationSatelliteTab />}
                    {step === 3 && <SimulationCreateFinalTab />}
                </Box>
                <HStack as={'nav'} justify="center" py={10}>
                    <Tooltip label="Go to the last step of the simulation creation.">
                        <IconButton
                            aria-label="Go to last step."
                            icon={<ArrowBackIcon />}
                            disabled={step <= 1}
                            onClick={goStepBack}
                        />
                    </Tooltip>
                    <Text>Step: {step}</Text>
                    <Tooltip label="Go to the next step of the simulation creation.">
                        <IconButton
                            aria-label="Go to next step."
                            icon={<ArrowForwardIcon />}
                            disabled={step >= 3 || hasError() || (step === 2 && sat === null)}
                            onClick={goStepForward}
                        />
                    </Tooltip>
                </HStack>
            </Flex>
        </Page>
    );
};

export default SimulationCreatePage;

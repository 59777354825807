import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Text,
    Tooltip
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import SwitchWithText from '../../../components/SwitchWithText';
import { useAuth } from '../../../context/AuthContext';
import BatteryCharge from '../../../types/BatteryCharge';
import Component from '../../../types/Component';
import { type Satellite } from '../../../types/Satellite';
import { type SimulationSatellite } from '../../../types/Simulation';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    handler: (sat: SimulationSatellite) => Promise<void>;
    startTime: Date | null;
};
const LoadRealtimeSatelliteModal = ({ isOpen, onClose, handler, startTime }: Props) => {
    const { axiosInstance } = useAuth();
    const [satellites, setSatellites] = useState<Satellite[]>([]);
    const [selectedSatellite, setSelectedSatellite] = useState<Satellite | null>(null);
    const [takeCurrentBatteryLoad, setTakeCurrentBatteryLoad] = useState(true);
    const [timeSource, setTimeSource] = useState<'current' | 'startTime'>('startTime');
    const [loading, setLoading] = useState(false);
    const [manualBoundCharge, setManualBoundCharge] = useState(0);
    const [manualAvailableCharge, setManualAvailableCharge] = useState(0);

    const loadSatellites = useCallback(async () => {
        try {
            const sats = await axiosInstance.get<Satellite[]>('/api/satellites');
            setSatellites(sats.data);
        } catch (e) {
            console.error(e);
            setSatellites([]);
        }
    }, [axiosInstance]);

    const loadSat = useCallback(async () => {
        // check for type safety, should never be true
        if (!selectedSatellite) return;

        if (timeSource === 'startTime' && !startTime) {
            window.alert('You need to specify a startTime');
            return;
        }

        const utcTime = startTime!.getTime() - startTime!.getTimezoneOffset() * 60 * 1000;

        const components: Component[] = selectedSatellite.components.map((comp) => {
            const temp: Component = {
                name: comp.name,
                background: comp.background,
                slewingAfter: comp.slewingAfter,
                slewingBefore: comp.slewingBefore,
                powerConsumption: comp.powerConsumption,
            };
            return temp;
        });

        const satData: SimulationSatellite = {
            id: selectedSatellite.id ?? -1,
            name: selectedSatellite.satelliteName,
            tle: selectedSatellite.tleURL,
            capacity: selectedSatellite.satBatteryConfig?.capacity ?? -1,
            initSocA: selectedSatellite.satBatteryConfig?.initSocA ?? -1,
            initSocB: selectedSatellite.satBatteryConfig?.initSocB ?? -1,
            c: selectedSatellite.satBatteryConfig?.c ?? -1,
            p: selectedSatellite.satBatteryConfig?.p ?? -1,
            components,
        };

        if (takeCurrentBatteryLoad) {
            const batteryChargeResponse =
                timeSource === 'current'
                    ? await axiosInstance.get<BatteryCharge>(
                          `/api/telemetry/${selectedSatellite.satelliteName}/download/batterycharge/current`
                      )
                    : await axiosInstance.get<BatteryCharge>(
                          `/api/telemetry/${selectedSatellite.satelliteName}/download/batterycharge/current/${utcTime}`
                      );
            const { availableCharge, boundCharge } = batteryChargeResponse.data;
            satData.initSocA = availableCharge;
            satData.initSocB = boundCharge;
        } else {
            satData.initSocA = manualAvailableCharge;
            satData.initSocB = manualBoundCharge;
        }

        handler(satData);
    }, [
        axiosInstance,
        handler,
        selectedSatellite,
        takeCurrentBatteryLoad,
        timeSource,
        startTime,
        manualAvailableCharge,
        manualBoundCharge,
    ]);

    useEffect(() => {
        loadSatellites();
    }, [loadSatellites]);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === '') {
            setSelectedSatellite(null);
            return;
        }

        const selected = satellites.find((sat) => sat.id === Number.parseInt(event.target.value));
        setSelectedSatellite(selected ? selected : null);
        if (selected) {
            setManualAvailableCharge(selected.satBatteryConfig?.initSocA ?? 0);
            setManualBoundCharge(selected.satBatteryConfig?.initSocB ?? 0);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Copy a realtime mode satellite</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack gap={5}>
                        <Box>
                            <Text mb={2} as="h4" fontWeight="semibold" fontSize="md">
                                Satellite
                            </Text>
                            <Select
                                placeholder="Select a satellite"
                                value={selectedSatellite ? selectedSatellite.id : ''}
                                onChange={handleSelectChange}
                            >
                                {satellites.map((sat) => (
                                    <option key={sat.id} value={sat.id}>
                                        {sat.satelliteName}
                                    </option>
                                ))}
                            </Select>
                        </Box>
                        <Box>
                            <Text mb={2} as="h4" fontWeight="semibold" fontSize="md">
                                Options
                            </Text>
                            <SwitchWithText
                                text={'Take real battery charges.'}
                                tooltip={'If enabled the database will be queried for real battery state.'}
                                isChecked={takeCurrentBatteryLoad}
                                onChange={() => setTakeCurrentBatteryLoad((val) => !val)}
                            />
                            {takeCurrentBatteryLoad ? (
                                <RadioGroup
                                    defaultValue={timeSource}
                                    mt="4"
                                    onChange={(val: string) => {
                                        if (val !== 'current' && val !== 'startTime') return;
                                        setTimeSource(val);
                                    }}
                                >
                                    <Stack>
                                        <Radio value={'current'} disabled={!takeCurrentBatteryLoad}>
                                            <Tooltip label={'Loads the battery status at the current second.'}>
                                                Current time
                                            </Tooltip>
                                        </Radio>

                                        <Radio value={'startTime'} disabled={!takeCurrentBatteryLoad}>
                                            <Tooltip
                                                label={
                                                    'Queries the database for the battery state at the specified start time of the simulation.'
                                                }
                                            >
                                                Simulation start time
                                            </Tooltip>
                                        </Radio>
                                    </Stack>
                                </RadioGroup>
                            ) : (
                                <Stack mt="4">
                                    <Text as="h4" fontWeight="semibold" fontSize="md">
                                        Available Charge [mW]
                                    </Text>
                                    <NumberInput
                                        step={1000}
                                        value={manualAvailableCharge}
                                        onChange={(_, val) => setManualAvailableCharge(val)}
                                        min={0}
                                        max={
                                            (selectedSatellite?.satBatteryConfig?.capacity ?? 0) *
                                            (selectedSatellite?.satBatteryConfig?.c ?? 0)
                                        }
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                    <Text as="h4" fontWeight="semibold" fontSize="md">
                                        Bound Charge [mW]
                                    </Text>
                                    <NumberInput
                                        step={1000}
                                        value={manualBoundCharge}
                                        min={0}
                                        onChange={(_, val) => setManualBoundCharge(val)}
                                        max={
                                            (selectedSatellite?.satBatteryConfig?.capacity ?? 0) *
                                            (1 - (selectedSatellite?.satBatteryConfig?.c ?? 0))
                                        }
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </Stack>
                            )}
                        </Box>
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="blue"
                        isLoading={loading}
                        isDisabled={selectedSatellite === null}
                        mr={3}
                        onClick={async () => {
                            setLoading(true);
                            await loadSat();
                            setLoading(false);
                            onClose();
                        }}
                    >
                        Load
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default LoadRealtimeSatelliteModal;

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
} from '@chakra-ui/react';
import IntervalGraph from '../../../components/IntervalGraph';
import IntervalTable from '../../../components/IntervalTable';
import { useSatDetailContext } from '../../../context/SatelliteDetailContext';
import { useSettings } from '../../../context/SettingsContext';
import SatelliteComponentOverview from './SatelliteComponentOverview';

const SatelliteInfoAccordion = () => {
    const { satellite } = useSatDetailContext();
    const { debug } = useSettings();
    return (
        <Accordion allowMultiple>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">
                            Components
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <SatelliteComponentOverview />
                </AccordionPanel>
            </AccordionItem>
            {debug && (
                <>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    Sun loading Intervals
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <IntervalTable
                                prependKey={'SUN'}
                                intervals={satellite.sunAccessIntervals}
                            />
                            <IntervalGraph
                                intervals={satellite.sunAccessIntervals}
                            />
                        </AccordionPanel>
                    </AccordionItem>

                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    Groundstation Access Intervals
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <IntervalTable
                                prependKey={'GROUNDSTATION'}
                                intervals={
                                    satellite.groundStationAccessIntervals
                                }
                            />
                            <IntervalGraph
                                intervals={
                                    satellite.groundStationAccessIntervals
                                }
                            />
                        </AccordionPanel>
                    </AccordionItem>
                </>
            )}
        </Accordion>
    );
};

export default SatelliteInfoAccordion;

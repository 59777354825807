import { ChakraProvider, Flex } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './components/MainRouter';
import AuthProvider from './context/AuthProvider';
import SettingsProvider from './context/SettingsProvider';

const App = () => {
    return (
        <ChakraProvider>
            <AuthProvider>
                <SettingsProvider>
                    <BrowserRouter>
                        <Flex flexDir="column" h={'100vh'} w={'100vw'}>
                            <MainRouter />
                        </Flex>
                    </BrowserRouter>
                </SettingsProvider>
            </AuthProvider>
        </ChakraProvider>
    );
};

export default App;

import { Button, Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { BreadcrumbItemProps } from '../../components/Breadcrumb';
import CustomCard from '../../components/CustomCard';
import Page from '../../components/Page';
import config from '../../Config';
import PreviousSimulationsOverview from './PreviousSimulationsOverview';

const PREFIX = config.SIMULATOR_PREFIX;
const breadCrumbItems: BreadcrumbItemProps[] = [
    {
        name: 'Home',
        to: `${PREFIX}/`,
    },
];
const SimulationPage = () => (
    <Page breadCrumbItems={breadCrumbItems} title="Simulations Overview" isLarge>
        <Text mb={'15px'} fontWeight="light" fontSize="lg" color={useColorModeValue('gray.600', 'gray.300')}>
            Here you can create a new simulation which will be saved in your local storage or load a previous one and
            start it again.
        </Text>
        <Text pt={'16px'} as="h3" fontWeight="semibold" fontSize="2xl">
            Actions
        </Text>
        <CustomCard>
            <Link to={`${config.SIMULATOR_PREFIX}/create`}>
                <Button>➕ Create new Simulation</Button>
            </Link>
        </CustomCard>
        <Text as="h2" pt={4} fontSize="2xl" fontWeight="semibold" textAlign={{ base: 'center', xl: 'left' }}>
            Previous Simulations
        </Text>
        <PreviousSimulationsOverview />
    </Page>
);

export default SimulationPage;

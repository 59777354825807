import { createContext, useContext } from 'react';

type ContextType = {
    includeFuture: boolean;
    startDate: Date | null;
    endDate: Date | null;
    everySecond: number;
    setIncludeFuture: React.Dispatch<React.SetStateAction<boolean>>;
    setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
    setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
    setEverySecond: React.Dispatch<React.SetStateAction<number>>;
};

export const TelemetryContext = createContext<ContextType>({
    includeFuture: false,
    startDate: null,
    endDate: null,
    everySecond: 550,
    setIncludeFuture: () => {},
    setStartDate: () => {},
    setEndDate: () => {},
    setEverySecond: () => {},
});

export const useTelemetryContext = () => useContext(TelemetryContext);

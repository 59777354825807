import { CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import {
    IconButton, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import OkCancelAlertDialog from '../../../components/OkCancelAlertDialog';
import Component from '../../../types/Component';

interface Props {
    components: Component[];
    setComponents: React.Dispatch<React.SetStateAction<Component[]>>;
}

const ComponentTable = ({ components, setComponents }: Props) => {
    const {
        isOpen: isDisclosureOpen,
        onOpen: onDisclosureOpen,
        onClose: onDisclosureClose,
    } = useDisclosure();
    const [clickedName, setClickedName] = useState<string | null>(null);

    const changeComponent = (componentName: string, consumption: number) => {
        setComponents((comps) => {
            const temp = [...comps]
            const item = temp.find((comp) => comp.name === componentName);
            if (!item) return temp;
            item.powerConsumption = isNaN(consumption) ? 0 : consumption
            return temp;
        });
    };

    useEffect(() => {
        if (clickedName === null) onDisclosureClose();
        else onDisclosureOpen();
    }, [clickedName, onDisclosureOpen, onDisclosureClose]);

    const text = useMemo(
        () => `You really want to delete the ${clickedName} component?`,
        [clickedName]
    );
    return (
        <>
            <OkCancelAlertDialog
                title={'Delete component'}
                text={text}
                isOpen={isDisclosureOpen}
                onClose={onDisclosureClose}
                cancelHandler={() => {
                    setClickedName(null);
                }}
                acceptHandler={() => {
                    if (clickedName === null) return;
                    setComponents((comps) =>
                        comps.filter((comp) => comp.name !== clickedName)
                    );
                    setClickedName(null);
                }}
            />
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th isNumeric>Background</Th>
                        <Th isNumeric>Slewing before [min]</Th>
                        <Th isNumeric>Slewing after [min]</Th>
                        <Th isNumeric>Power consumption [mW]</Th>
                        <Th isNumeric>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {components.map((component) => (
                        <Tr key={component.name}>
                            <Td>{component.name}</Td>
                            <Td isNumeric>
                                {component.background ? (
                                    <CheckIcon />
                                ) : (
                                    <CloseIcon />
                                )}
                            </Td>
                            <Td isNumeric>{component.slewingBefore}</Td>
                            <Td isNumeric>{component.slewingAfter}</Td>
                            <Td isNumeric>
                                {component.isStatic ? (
                                    <NumberInput size='sm' step={100} value={component.powerConsumption} allowMouseWheel onChange={(_, num) => {
                                        changeComponent(component.name, num);
                                    }}>
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>) : (<Text>{component.powerConsumption}</Text>)}
                            </Td>
                            <Td isNumeric>
                                <IconButton
                                    size={'sm'}
                                    colorScheme="red"
                                    aria-label="Delete Component"
                                    icon={<DeleteIcon />}
                                    disabled={component.isStatic}
                                    onClick={() =>
                                        setClickedName(component.name)
                                    }
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            {
                components.length === 0 && (
                    <VStack pb={4}>
                        <Text fontSize="sm">0 components added.</Text>
                    </VStack>
                )
            }
        </>
    );
};

export default ComponentTable;

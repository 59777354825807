import { Button, Stack } from '@chakra-ui/react';
import { JsonInput, JsonInputProps } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';

const JsonInputField = ({
    uploadOperation,
    onChange,
    ...rest
}: JsonInputProps & { uploadOperation: (value: string) => void }) => {
    const ref = useRef<HTMLTextAreaElement>(null);
    const [input, setInput] = useState('');
    const [valid, setValid] = useState(false);

    const validateJson = (value: string) => {
        if (!value || value === '') return false;
        try {
            JSON.parse(value);
            return true;
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        setValid(validateJson(input));
    }, [input]);

    return (
        <Stack>
            <JsonInput ref={ref} onChange={setInput} value={input} {...rest} />
            <Button disabled={!valid} onClick={() => uploadOperation(input)}>
                Upload operation
            </Button>
        </Stack>
    );
};

export default JsonInputField;

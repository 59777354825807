import { RepeatIcon } from '@chakra-ui/icons';
import { Button, Flex } from '@chakra-ui/react';

interface Props {
    loading: boolean;
    onClick: () => void;
}

const RefreshButton = ({ loading, onClick }: Props) => {
    return (
        <Flex align="center" justify="end">
            <Button
                leftIcon={<RepeatIcon />}
                bg={'blue.400'}
                color={'white'}
                _hover={{
                    bg: 'blue.500',
                }}
                isLoading={loading}
                onClick={onClick}
            >
                Refresh
            </Button>
        </Flex>
    );
};

export default RefreshButton;

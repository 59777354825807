import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';
import { useRef } from 'react';

interface Props {
    title?: string;
    text?: string;
    confirm?: boolean;
    isOpen: boolean;
    onClose: () => void;
    acceptHandler?: () => void;
    cancelHandler?: () => void;
}

const OkCancelAlertDialog = ({
    title,
    text,
    confirm = false,
    isOpen,
    acceptHandler,
    cancelHandler,
    onClose,
}: Props) => {
    const cancelRef = useRef<any>();
    return (
        <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelRef}
            onClose={onClose}
            isOpen={isOpen}
            isCentered
        >
            <AlertDialogOverlay />

            <AlertDialogContent>
                <AlertDialogHeader>{title ? title : 'Alert'}</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>{text && text}</AlertDialogBody>
                <AlertDialogFooter>
                    {confirm ? (
                        <Button
                            onClick={() => {
                                acceptHandler && acceptHandler();
                            }}
                        >
                            Ok
                        </Button>
                    ) : (
                        <>
                            <Button
                                ref={cancelRef}
                                onClick={() => {
                                    cancelHandler && cancelHandler();
                                }}
                            >
                                No
                            </Button>
                            <Button
                                colorScheme="red"
                                ml={3}
                                onClick={() => {
                                    acceptHandler && acceptHandler();
                                }}
                            >
                                Yes
                            </Button>
                        </>
                    )}
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default OkCancelAlertDialog;

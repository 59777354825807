import BatteryCharge from '../types/BatteryCharge';

const transformCharges = (everySecondAmount: number, charges: BatteryCharge[]) => {
    const seriesAvailable: [number, number][] = [];
    const seriesBound: [number, number][] = [];
    let index = 0;
    charges.forEach(charge => {   
        if(charge.timestamp % everySecondAmount === 0 || index === 0) {
            seriesAvailable.push([charge.timestamp * 1000, charge.availableCharge]);
            seriesBound.push([charge.timestamp * 1000, charge.boundCharge]);
        }
        index++;
    });
    return {
        seriesAvailable,
        seriesBound
    };
};

export default transformCharges;

import { Box, HStack, Spinner, Text, useColorModeValue } from '@chakra-ui/react';

type Props = {
    socketState: number;
};

const WebsocketIndicator = ({ socketState }: Props) => {
    const bgColor = useColorModeValue('white', 'gray.700');

    return (
        <HStack bgColor={bgColor} p="2" rounded={'lg'} boxShadow={'lg'} alignItems="center">
            <Text fontWeight="light" fontSize="sm">
                Websocket:
            </Text>
            <HStack>
                {socketState === 0 && (
                    <>
                        <Spinner size="sm" />
                        <Text>Connecting</Text>
                    </>
                )}
                {socketState === 1 && (
                    <>
                        <Box bgColor={'green'} w="15px" h="15px" rounded="full" />
                        <Text>Connected</Text>
                    </>
                )}
                {socketState === 2 && (
                    <>
                        <Spinner size="sm" />
                        <Text>Closing</Text>
                    </>
                )}
                {socketState === 3 && (
                    <>
                        <Box bgColor={'red'} w="15px" h="15px" rounded="full" />
                        <Text>Closed</Text>
                    </>
                )}
            </HStack>
        </HStack>
    );
};

export default WebsocketIndicator;

import config from "../../Config";

export interface NavItem {
    label: string;
    hideUnauthenticated: boolean;
    hideAuthenticated: boolean;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
}

const PREFIX = config.REALTIME_PREFIX;


export const NAV_ITEMS: Array<NavItem> = [
    {
        label: 'Satellites',
        hideUnauthenticated: true,
        hideAuthenticated: false,
        href: '#',
        children: [
            {
                label: 'Show Satellites',
                hideUnauthenticated: true,
                hideAuthenticated: false,
                subLabel: 'Shows overview about all satellites.',
                href: `${PREFIX}/satellites`
            },
            {
                label: 'Create new Satellite',
                hideUnauthenticated: true,
                hideAuthenticated: false,
                subLabel: 'Opens the page to create new satellites.',
                href: `${PREFIX}/satellites/create`
            },
        ],
    },
    {
        label: 'Groundstations',
        hideUnauthenticated: true,
        hideAuthenticated: false,
        href: '#',
        children: [
            {
                label: 'Show Groundstations',
                hideUnauthenticated: true,
                hideAuthenticated: false,
                subLabel: 'Shows overview about all groundstations.',
                href: `${PREFIX}/groundstations`
            },
            {
                label: 'Create new Groundstation',
                hideUnauthenticated: true,
                hideAuthenticated: false,
                subLabel: 'Opens the page to create new groundstations.',
                href: `${PREFIX}/groundstations/create`
            },
        ],
    },
    {
        label: 'Fleetview',
        hideUnauthenticated: true,
        hideAuthenticated: false,
        href: `${PREFIX}/fleetview`
    },
];

import { ReactNode, useCallback, useState } from 'react';
import { AccessInterval } from '../types/Satellite';
import { SimulationGroundStation, SimulationSatellite } from '../types/Simulation';
import { SimulationCreateContext } from './SimulationCreateContext';
interface Props {
    children?: ReactNode;
}

const SimulationCreateProvider = ({ children }: Props) => {
    const [passes, setPasses] = useState<AccessInterval[]>([]);
    const [errors, setErrors] = useState<Set<string>>(new Set<string>());
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(new Date(new Date().getTime() + 1000 * 60 * 60 * 24));
    const [sat, setSat] = useState<SimulationSatellite | null>(null);
    const [groundStation, setGroundStation] = useState<SimulationGroundStation | null>(null);

    const addError = useCallback(
        (name: string) => {
            setErrors((oldErrors) => {
                const tempSet = new Set(oldErrors);
                tempSet.add(name);
                return tempSet;
            });
        },
        [setErrors]
    );

    const removeError = useCallback(
        (name: string) => {
            setErrors((oldErrors) => {
                const tempSet = new Set(oldErrors);
                tempSet.delete(name);
                return tempSet;
            });
        },
        [setErrors]
    );

    const isError = useCallback((name: string) => errors.has(name), [errors]);
    const hasError = useCallback(() => errors.size > 0, [errors]);

    return (
        <SimulationCreateContext.Provider
            value={{
                startDate,
                endDate,
                sat,
                groundStation,
                passes,
                setStartDate,
                setEndDate,
                setSat,
                setGroundStation,
                setPasses,
                hasError,
                isError,
                addError,
                removeError,
            }}
        >
            {children}
        </SimulationCreateContext.Provider>
    );
};

export default SimulationCreateProvider;

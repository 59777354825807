import {
    Box,
    Button,
    Checkbox,
    Flex,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Stack,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CustomCard from '../../components/CustomCard';
import config from '../../Config';
import { useSimulationContext } from '../../context/SimulationContext';
import { useSimulationCreateContext } from '../../context/SimulationCreateContext';
import { Simulation } from '../../types/Simulation';
import { createTimeString } from '../../utils/createTimeString';
import { SatellitePreview } from './SimulationSatelliteTab';

const PREFIX = config.SIMULATOR_PREFIX;
const SimulationCreateFinalTab = () => {
    const [name, setName] = useState('');
    const [seed, setSeed] = useState('');
    const [includeSlewing, setIncludeSlewing] = useState(false);
    const { sat, startDate, endDate } = useSimulationCreateContext();
    const { setStoredSimulations } = useSimulationContext();
    const history = useHistory();

    const createSimulation = useCallback(async () => {
        if (!sat || sat.tle === '' || !startDate || !endDate) return;

        const newSimulation: Simulation = {
            name,
            id: uuidv4(),
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            created: new Date().getTime(),
            satellite: sat,
            seed: seed === '' ? null : Number.parseInt(seed),
            includeSlewing: includeSlewing,
        };

        setStoredSimulations((simulations) => {
            const oldSimulations = [...simulations];
            oldSimulations.push(newSimulation);
            return oldSimulations;
        });

        history.push(PREFIX);
    }, [name, history, sat, startDate, endDate, setStoredSimulations, seed, includeSlewing]);

    return (
        <Stack pb={6}>
            <Text as="h2" fontWeight="semibold" fontSize="2xl">
                Overview
            </Text>
            <CustomCard>
                <Text mt={'5px'} as="h3" fontWeight="semibold" fontSize="xl">
                    Simulation name:
                </Text>
                <Input
                    placeholder="Simulation name"
                    name={name}
                    onChange={(event) => setName(event.target.value)}
                    size="md"
                />
            </CustomCard>
            <CustomCard>
                <Text mt={'5px'} as="h3" fontWeight="semibold" fontSize="xl">
                    Simulation random seed:
                </Text>
                <NumberInput value={seed} onChange={(numStr) => setSeed(numStr)}>
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </CustomCard>
            <CustomCard>
                <Text mt={'5px'} as="h3" fontWeight="semibold" fontSize="xl">
                    Include slewing:
                </Text>
                <Checkbox checked={includeSlewing} onChange={(event) => setIncludeSlewing(event.target.checked)}>
                    Include slewing in final merged operation
                </Checkbox>
            </CustomCard>
            <CustomCard>
                <Text mt={'5px'} as="h3" fontWeight="semibold" fontSize="xl">
                    Simulation times:
                </Text>
                <Flex gap={10}>
                    <Box>
                        <Text as="h4" fontWeight="semibold" fontSize="xl">
                            Start date:
                        </Text>
                        <Text>{startDate && createTimeString(startDate)}</Text>
                    </Box>
                    <Box>
                        <Text as="h4" fontWeight="semibold" fontSize="xl">
                            End date:
                        </Text>
                        <Text>{endDate && createTimeString(endDate)}</Text>
                    </Box>
                </Flex>
            </CustomCard>
            <CustomCard>
                <SatellitePreview sat={sat} />
            </CustomCard>
            <Text pt={'5px'} as="h2" fontWeight="semibold" fontSize="2xl">
                Actions
            </Text>
            <CustomCard>
                <Flex gap="5" flexDir={{ base: 'column', md: 'row' }}>
                    <Tooltip
                        hasArrow
                        label="Saves the calculation and returns to the simulation overview screen."
                        placement="top"
                        fontSize="md"
                    >
                        <Button
                            disabled={name === '' || !sat || sat.tle === '' || !startDate || !endDate}
                            onClick={() => createSimulation()}
                        >
                            Create simulation
                        </Button>
                    </Tooltip>
                </Flex>
            </CustomCard>
        </Stack>
    );
};

export default SimulationCreateFinalTab;

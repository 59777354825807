import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { AccessInterval } from '../types/Satellite';
import { getLocalTimeInterval } from '../utils/getLocalTimeInterval';

interface Props {
    prependKey: string;
    intervals: AccessInterval[];
}

const IntervalTable = ({ prependKey, intervals }: Props) => {
    return (
        <Table>
            <Thead>
                <Tr>
                    <Th>
                        <Text fontWeight="semibold">ID:</Text>
                    </Th>
                    <Th>
                        <Text fontWeight="semibold">Period:</Text>
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {intervals.map((period, index) => (
                    <Tr key={`${prependKey}-${period.start}-${period.end}`}>
                        <Td>
                            <Text>{index}</Text>
                        </Td>
                        <Td>
                            <Text fontSize="sm">
                                {getLocalTimeInterval(period)}
                            </Text>
                        </Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

export default IntervalTable;

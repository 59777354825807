import {
    Checkbox,
    HStack,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CustomReactDatepicker from '../../../components/CustomReactDatepicker';
import { useSettings } from '../../../context/SettingsContext';
import { useTelemetryContext } from '../../../context/TelemetryContext';

const TelemetryHeader = () => {
    const { debug } = useSettings();
    const {
        includeFuture,
        setIncludeFuture,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        everySecond,
        setEverySecond,
    } = useTelemetryContext();
    const [tempVar, setTempVar] = useState(everySecond);
    const onChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleChange = useCallback(
        (num: number) => {
            setEverySecond(num);
        },
        [setEverySecond]
    );

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300);
    }, [handleChange]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    return (
        <HStack
            align="center"
            justify="space-around"
            spacing="5"
            border="1px"
            mb={5}
            borderColor="gray.100"
            p="4"
            shadow="md"
        >
            <HStack>
                <Tooltip label="Defines the time period to be displayed for the following graphs.">
                    <Text>Date range:</Text>
                </Tooltip>
                <CustomReactDatepicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    showWeekNumbers
                    withPortal
                />
            </HStack>
            {debug && (
                <Tooltip label="Contain data that should not be available now because it has not yet been downloaded or is in the future.">
                    <HStack>
                        <Text>Future:</Text>
                        <Checkbox
                            checked={includeFuture}
                            onChangeCapture={() => {
                                setIncludeFuture((old) => !old);
                            }}
                        />
                    </HStack>
                </Tooltip>
            )}
            <HStack>
                <Tooltip label="Sets the granularity of the battery charge graph in seconds.">
                    <Text>BatteryCharge precision [s]:</Text>
                </Tooltip>
                <NumberInput
                    value={tempVar}
                    onChange={(_str, num) => {
                        setTempVar(num);
                        debouncedResults(num);
                    }}
                    min={150}
                >
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            </HStack>
        </HStack>
    );
};

export default TelemetryHeader;

import { Box, useColorModeValue } from '@chakra-ui/react';
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useSatDetailContext } from '../../../context/SatelliteDetailContext';
import { useTelemetryContext } from '../../../context/TelemetryContext';
import BatteryCharge from '../../../types/BatteryCharge';
import { createDateString } from '../../../utils/createDateString';
import transformCharges from '../../../utils/transformCharges';

const TelemetryLoadTrace = () => {
    const theme = useColorModeValue('light', 'dark');
    const { axiosInstance } = useAuth();
    const { satellite } = useSatDetailContext();
    const { includeFuture, startDate, endDate, everySecond } = useTelemetryContext();
    const [batteryCharges, setBatteryCharges] = useState<BatteryCharge[]>([]);

    const { seriesAvailable, seriesBound } = useMemo(() => {
        const set = everySecond < 150 ? 150 : everySecond;
        return transformCharges(set, batteryCharges);
    }, [batteryCharges, everySecond]);

    const loadBatteryCharges = useCallback(async () => {
        if (!startDate || !endDate) {
            setBatteryCharges([]);
            return;
        }
        try {
            const apiData = await axiosInstance.get<BatteryCharge[]>(
                `/api/telemetry/${satellite.satelliteName
                }/download/batterycharge/${createDateString(
                    startDate
                )}/${createDateString(endDate)}`,
                {
                    params: {
                        future: includeFuture,
                    },
                }
            );
            setBatteryCharges(apiData.data);
        } catch (err) {
            console.error(err);
            setBatteryCharges([]);
        }
    }, [axiosInstance, satellite, startDate, endDate, includeFuture]);

    useEffect(() => {
        loadBatteryCharges();
    }, [loadBatteryCharges]);

    return (
        <Box h={"500px"}>
            <ReactEcharts
                theme={theme}
                style={{
                    height: 500
                }}
                option={{
                    useUTC: true,
                    title: {
                        left: 'center',
                        text: "Battery charge"
                    },
                    legend: {
                        data: ['Available Charge', 'Bound Charge'],
                        right: 90,
                        top: 0
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    grid: {
                        top: 70,
                        left: 90,
                        right: 60,
                        height: 350,
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            throttle: 50
                        }
                    ],
                    toolbox: {
                        left: 'center',
                        top: 30,
                        itemSize: 20,
                        feature: {
                            saveAsImage: {},
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                        },
                    },

                    xAxis: {
                        type: "time",
                    },
                    yAxis: {
                        type: "value",
                        name: "in mW"
                    },
                    series: [
                        {
                            name: "Available Charge",
                            data: seriesAvailable,
                            type: "line",
                            stack: "Total",
                            areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                        },
                        {
                            name: "Bound Charge",
                            type: "line",
                            stack: "Total",
                            areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                            data: seriesBound,
                        }
                    ]
                }}
            />
        </Box>
    );
};

export default TelemetryLoadTrace;

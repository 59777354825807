import { SpinnerIcon } from '@chakra-ui/icons';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Divider,
    HStack,
    Stack,
    Text,
} from '@chakra-ui/react';
import { JsonInput } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BreadcrumbItemProps } from '../../components/Breadcrumb';
import CustomCard from '../../components/CustomCard';
import DatapointTable from '../../components/DatapointTable';
import JsonInputField from '../../components/JsonInputField';
import Page from '../../components/Page';
import WebsocketIndicator from '../../components/WebsocketIndicator';
import config from '../../Config';
import { useAuth } from '../../context/AuthContext';
import { useSimulationContext } from '../../context/SimulationContext';
import Datapoint from '../../types/Datapoint';
import { GroundStationPass, Simulation } from '../../types/Simulation';
import { createTimeString } from '../../utils/createTimeString';
import {
    generateAuthMessage,
    generateHeartbeatMessage,
    generateHelloMessage,
    generateOperationMessage,
    generateSkipMessage,
} from '../../utils/WebSocketUtils';

const PREFIX = config.SIMULATOR_PREFIX;
const SimulationRunPage = () => {
    const { token } = useAuth();
    const params = useParams<{ id: string }>();
    const { storedSimulations } = useSimulationContext();
    const history = useHistory();
    const [runSimulation, setRunSimulation] = useState<Simulation | null>(null);
    const [websocket, setWebsocket] = useState<WebSocket | null>(null);
    const [nextPass, setNextPass] = useState<GroundStationPass | null>(null);
    const [passes, setPasses] = useState<GroundStationPass[]>([]);
    const [summary, setSummary] = useState<{ dataPoints: Datapoint[]; operation: string } | null>(null);
    const [finished, setFinished] = useState(false);
    const [heartbeat, setHeartbeat] = useState(false);
    const [socketState, setSocketState] = useState(0);

    const breadCrumbItems: BreadcrumbItemProps[] = useMemo(
        () => [
            {
                name: 'Home',
                to: `${PREFIX}/`,
            },
            {
                name: params.id,
                to: `${PREFIX}/run/${params.id}`,
            },
        ],
        [params]
    );

    useEffect(() => {
        if (!websocket) return;
        const simulation = storedSimulations.find((sim) => sim.id === params.id);
        if (!simulation) {
            history.push(`${PREFIX}/not-found`);
        } else {
            setRunSimulation({ ...simulation });
            websocket.send(generateHelloMessage(simulation));
        }
    }, [history, storedSimulations, params.id, websocket]);

    useEffect(() => {
        if (finished) return;
        const ws = new WebSocket(config.WS_URL);
        ws.onmessage = (event: MessageEvent) => {
            const message = JSON.parse(event.data);
            if (message.type === 'SUCCESS' && message.message === 'Successfully logged in.') {
                setWebsocket(ws);
                setSocketState(1);
            } else if (message.type === 'SUCCESS' && message.message.startsWith('Satellite with ID=')) {
                console.log('Sat was accepted.');
            } else if (
                message.type === 'SUCCESS' &&
                message.message === 'Simulation has reached the last pass. Simulation finished.'
            ) {
                setFinished(true);
                setSocketState(2);
            } else if (message.type === 'SUMMARY') {
                setSummary({
                    dataPoints: message.datapoints,
                    operation: JSON.stringify(message.operation, null, 2),
                });
            } else if (message.type === 'ERROR') {
                console.error(message.message);
            } else if (message.type === 'TELEMETRY') {
                const nextPass: GroundStationPass = {
                    dataPoints: message.data_points,
                    nextPass: message.next_groundstation_pass,
                };
                setNextPass(nextPass);
                setPasses((oldPasses) => {
                    const tempPasses = [...oldPasses];
                    tempPasses.push(nextPass);
                    return tempPasses;
                });
            } else {
                console.log(message);
            }
        };

        ws.onopen = (ev: Event) => {
            ws.send(generateAuthMessage(token));
            setHeartbeat(true);
        };

        ws.onerror = (ev: Event) => {
            console.error(ev);
        };

        ws.onclose = (ev: Event) => {
            console.log(ev);
            setSocketState(3);
            setHeartbeat(false);
        };

        // clean up function
        return () => ws.close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!heartbeat || !websocket) return;

        const interval = setInterval(() => {
            websocket.send(generateHeartbeatMessage());
        }, 45000);

        return () => {
            clearInterval(interval);
        };
    }, [websocket, heartbeat]);

    const skipOperation = () => {
        if (!websocket) return;
        const generatedMessage = generateSkipMessage();
        websocket.send(generatedMessage);
    };

    const uploadOperation = (operation: string) => {
        if (!websocket) return;
        const generatedMessage = generateOperationMessage(operation);
        if (generatedMessage === '') return;
        websocket.send(generatedMessage);
    };

    if (!websocket) return <>Connecting and authenticating to backend...</>;
    if (!runSimulation) return <>Loading...</>;

    return (
        <Page breadCrumbItems={breadCrumbItems} title="Simulation runner">
            <HStack justify="flex-end">
                <Button
                    leftIcon={<SpinnerIcon />}
                    onClick={() => {
                        websocket.close();
                        history.push('/');
                        history.goBack();
                    }}
                >
                    Restart
                </Button>
                <WebsocketIndicator socketState={socketState} />
            </HStack>
            <CustomCard name="Info">
                {nextPass && (
                    <>
                        <Stack>
                            {!finished && nextPass.nextPass && (
                                <HStack>
                                    <Box>
                                        <Text>Next pass: </Text>
                                    </Box>
                                    <Box>
                                        <Text>
                                            {`${createTimeString(new Date(nextPass.nextPass * 1000))} (${
                                                nextPass.nextPass
                                            })`}
                                        </Text>
                                    </Box>
                                </HStack>
                            )}
                            {finished && (
                                <HStack>
                                    <Text>Simulation is finished.</Text>
                                </HStack>
                            )}
                            <Accordion allowToggle allowMultiple>
                                {passes.map((pass, index) => (
                                    <AccordionItem key={pass.nextPass}>
                                        <h2>
                                            <AccordionButton>
                                                <Box flex="1" textAlign="left">
                                                    <Text>
                                                        {index === 0
                                                            ? `[Initial] ${Math.floor(
                                                                  runSimulation.startDate / 1000
                                                              )} - ${pass.nextPass}`
                                                            : `[Step ${index}] ${passes[index - 1].nextPass} - ${
                                                                  pass.nextPass ?? 'Last pass'
                                                              }`}
                                                    </Text>
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pt="8">
                                            <DatapointTable dataPoints={pass.dataPoints} />
                                        </AccordionPanel>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </Stack>
                    </>
                )}
            </CustomCard>
            {!finished && (
                <CustomCard name="Action">
                    <Button onClick={skipOperation}>Skip this pass</Button>
                    <Divider />
                    <JsonInputField
                        label="Upload operation"
                        placeholder="Insert the next operation for the simulation"
                        validationError="Invalid json"
                        formatOnBlur
                        autosize
                        minRows={12}
                        maxRows={12}
                        uploadOperation={uploadOperation}
                    />
                </CustomCard>
            )}
            {finished && summary && (
                <CustomCard name="Summary">
                    <Text fontWeight="medium" fontSize="lg">
                        Merged Operation
                    </Text>
                    <JsonInput value={summary.operation} autosize />
                    <Divider />
                    <Text mt="8" fontWeight="medium" fontSize="lg">
                        Datapoints
                    </Text>
                    <DatapointTable dataPoints={summary.dataPoints} />
                </CustomCard>
            )}
        </Page>
    );
};

export default SimulationRunPage;

import { AccessInterval } from '../types/Satellite';

export const isTimeInIntervals = (
    time: number,
    intervals: AccessInterval[]
): boolean => {
    return (
        intervals.findIndex(
            (interval) => time <= interval.end && time >= interval.start
        ) !== -1
    );
};

import { HStack, Skeleton } from "@chakra-ui/react";
import {
    Cartesian2,
    Cartesian3, Color,
    createWorldTerrain,
    JulianDate, PolylineGlowMaterialProperty,
    SampledPositionProperty, Viewer as CesiumViewer
} from 'cesium';
import { useCallback, useEffect, useRef, useState } from "react";
import { CesiumComponentRef, Viewer } from "resium";
import { eciToGeodetic, EciVec3, gstime, propagate, twoline2satrec } from "satellite.js";
import { BreadcrumbItemProps } from "../../components/Breadcrumb";
import Page from "../../components/Page";
import RefreshButton from "../../components/RefreshButton";
import config from "../../Config";
import { useAuth } from "../../context/AuthContext";
import GroundStation from "../../types/GroundStation";
import { Satellite } from "../../types/Satellite";
const terrainProvider = createWorldTerrain();

const breadCrumbItems: BreadcrumbItemProps[] = [
    {
        name: 'Home',
        to: config.REALTIME_PREFIX,
    },
    {
        name: 'Fleetview',
        to: `${config.REALTIME_PREFIX}/fleetview`,
    },
];

const FleetView = () => {
    const { axiosInstance } = useAuth();
    const [dataLoaded, setDataLoaded] = useState(false);
    const viewerRef = useRef<CesiumComponentRef<CesiumViewer>>(null);

    const loadGroundStations = useCallback(async () => {
        try {
            const groundStations = await axiosInstance.get<GroundStation[]>(
                '/api/groundstations'
            );
            return groundStations.data;
        } catch (err) {
            console.error('Could not load groundstations from API.');
            return [];
        }
    }, [axiosInstance]);

    const loadSatellites = useCallback(async () => {
        try {
            const satellites = await axiosInstance.get<Satellite[]>(
                '/api/satellites'
            );
            return satellites.data;
        } catch (err) {
            console.error('Could not load satellites from API.');
            return [];
        }
    }, [axiosInstance]);

    const loadData = useCallback(async () => {
        const [groundStations, satellites] = await Promise.all([loadGroundStations(), loadSatellites()]);
        setDataLoaded(true);
        if (viewerRef.current?.cesiumElement) {
            const viewer = viewerRef.current.cesiumElement;

            const totalSeconds = 60 * 60 * 6;
            const timestepInSeconds = 10;
            const start = JulianDate.fromDate(new Date());
            const stop = JulianDate.addSeconds(
                start,
                totalSeconds,
                new JulianDate()
            );
            viewer.clock.startTime = start.clone();
            viewer.clock.stopTime = stop.clone();
            viewer.clock.currentTime = start.clone();
            viewer.timeline.zoomTo(start, stop);
            viewer.clock.multiplier = 1;
            // TODO: Fix after new version is released; Switch Back to ClockRange.LOOP_STOP.
            // viewer.clock.clockRange = ClockRange.LOOP_STOP;
            viewer.clock.clockRange = 2;
            viewer.scene.globe.enableLighting = true;

            viewer.entities.removeAll();

            groundStations.forEach((gs) => {
                viewer.entities.add({
                    name: gs.groundStationName,
                    position: Cartesian3.fromDegrees(gs.longitude, gs.latitude, gs.altitude),
                    point: {
                        pixelSize: 4,
                        color: Color.RED,
                        outlineColor: Color.WHITE,
                        outlineWidth: 1,
                    },
                    label: {
                        text: gs.groundStationName,
                        font: '14px sans-serif',
                        // TODO: Fix after new version is released; Switch Back to LabelStyle.FILL_AND_OUTLINE.
                        // style: LabelStyle.FILL_AND_OUTLINE,
                        style: 2,
                        outlineWidth: 2,
                        // TODO: Fix after new version is released; Switch Back to VerticalOrigin.BOTTOM.
                        // verticalOrigin: VerticalOrigin.BOTTOM,
                        verticalOrigin: 1,
                        pixelOffset: new Cartesian2(0, -9),
                    },
                });
            });

            satellites.forEach((sat) => {
                const positionsOverTime = new SampledPositionProperty();
                const satrec = twoline2satrec(
                    sat.tle.split('\n')[0].trim(),
                    sat.tle.split('\n')[1].trim()
                );
                for (let i = 0; i < totalSeconds; i += timestepInSeconds) {
                    const time = JulianDate.addSeconds(start, i, new JulianDate());
                    const jsDate = JulianDate.toDate(time);

                    const positionAndVelocity = propagate(satrec, jsDate);
                    const gmst = gstime(jsDate);
                    const p = eciToGeodetic(
                        positionAndVelocity.position as EciVec3<number>,
                        gmst
                    );

                    const position = Cartesian3.fromRadians(
                        p.longitude,
                        p.latitude,
                        p.height * 1000
                    );
                    positionsOverTime.addSample(time, position);
                }
                viewer.entities.add({
                    position: positionsOverTime,
                    point: { pixelSize: 5, color: Color.RED },
                    label: {
                        text: sat.satelliteName,
                        font: '12px sans-serif',
                        // TODO: Fix after new version is released; Switch Back to LabelStyle.FILL_AND_OUTLINE.
                        // style: LabelStyle.FILL_AND_OUTLINE,
                        style: 2,
                        outlineWidth: 1,
                        // TODO: Fix after new version is released; Switch Back to VerticalOrigin.BOTTOM.
                        // verticalOrigin: VerticalOrigin.BOTTOM,
                        verticalOrigin: 1,
                        pixelOffset: new Cartesian2(0, -9),
                    },
                    path: {
                        leadTime: 60,
                        trailTime: 60,
                        resolution: 1,
                        material: new PolylineGlowMaterialProperty({
                            glowPower: 0.1,
                            color: Color.ROYALBLUE,
                        }),
                        width: 10,
                    },
                });
            });

            let initialized = false;
            viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
                if (!initialized && viewer.scene.globe.tilesLoaded === true) {
                    viewer.clock.shouldAnimate = true;
                    initialized = true;
                }
            });
        }
    }, [loadGroundStations, loadSatellites]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Page breadCrumbItems={breadCrumbItems} isLarge>
            <HStack justifyContent={'flex-end'}>
                <RefreshButton
                    onClick={() => loadData()}
                    loading={!dataLoaded}
                />
            </HStack>
            <Skeleton isLoaded={dataLoaded}>
                <Viewer
                    ref={viewerRef}
                    homeButton={false}
                    fullscreenButton={false}
                    scene3DOnly={true}
                    baseLayerPicker={false}
                    geocoder={false}
                    infoBox={false}
                    navigationHelpButton={false}
                    sceneModePicker={false}
                    terrainProvider={terrainProvider}
                />
            </Skeleton>
        </Page>
    );
}

export default FleetView;


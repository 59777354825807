import { Flex, Link, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Page from '../components/Page';
import config from '../Config';

const AppCard = ({ name, url, icon }: { name: string, url: string, icon: string }) => {
    const bgColor = useColorModeValue('white', 'gray.700');
    const bgColorHover = useColorModeValue('gray.50', 'gray.600');

    return (
        <Link
            as={RouterLink}
            to={url}
            h="350px"
            w="400px"
            _hover={{
                textDecoration: "none"
            }}
        >
            <Stack
                p="4"
                w={"100%"}
                h={"100%"}
                bg={bgColor}
                rounded={'lg'}
                boxShadow={'lg'}
                cursor={"pointer"}
                _hover={{
                    bg: bgColorHover
                }}
            >
                <Text fontSize='2xl' fontWeight='bold' align="center">{name}</Text>
                <Flex justify="center" align="center" w="100%" h="100%">
                    <Text fontSize='8xl' align="center">{icon}</Text>
                </Flex>
            </Stack>
        </Link>
    );
};

const AppSelectPage = () => (
    <Page title={"Select your application"}>
        <Flex justify={{ base: "center", xl: "flex-start" }} w="100%" h="100%" flexWrap={"wrap"} gap={"45px"}>
            <AppCard name={"Realtime Satellite Viewer"} url={config.REALTIME_PREFIX} icon="🛰️" />
            <AppCard name={"Simulator"} url={config.SIMULATOR_PREFIX} icon="📡" />
            <AppCard name={"Settings"} url={config.SETTINGS_ROUTE} icon="⚙️" />
            <AppCard name={"Logs"} url={config.LOGS_ROUTE} icon="🏴󠁡󠁦󠁬󠁯󠁧󠁿" />
        </Flex>
    </Page>
);

export default AppSelectPage;

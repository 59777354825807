import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import Battery from '../../../types/Battery';
import BatteryValidationSchema from './BatteryValidationSchema';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setBatteryHandler: (bat: Battery) => void;
}

const BatteryModal = ({ isOpen, onClose, setBatteryHandler }: Props) => {
    const createBattery = (bat: Battery) => {
        setBatteryHandler(bat);
    };

    return (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Edit the KiBaM</ModalHeader>
                <ModalCloseButton />
                <Formik
                    initialValues={{
                        capacity: 0,
                        initSocA: 0,
                        initSocB: 0,
                        c: 1,
                        p: 0,
                    }}
                    validationSchema={BatteryValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        createBattery(values);
                        setSubmitting(false);
                        onClose();
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <ModalBody pb={6}>
                                    <Stack spacing={5} w={'full'}>
                                        <FormControl id="Capacity">
                                            <FormLabel>Capacity [mJ]</FormLabel>
                                            <Input
                                                name="capacity"
                                                id="capacity"
                                                placeholder="Capacity"
                                                _placeholder={{
                                                    color: 'gray.500',
                                                }}
                                                isInvalid={
                                                    touched.capacity &&
                                                    errors.capacity !==
                                                        undefined
                                                }
                                                onChange={handleChange}
                                                value={values.capacity}
                                                type="number"
                                            />
                                            {touched.capacity &&
                                                errors.capacity && (
                                                    <Text color="red.500">
                                                        {errors.capacity}
                                                    </Text>
                                                )}
                                        </FormControl>
                                        <FormControl id="initSocA">
                                            <FormLabel>
                                                Initial Available Charge [mJ]
                                            </FormLabel>
                                            <Input
                                                name="initSocA"
                                                id="initSocA"
                                                placeholder="Initial Available Charge"
                                                _placeholder={{
                                                    color: 'gray.500',
                                                }}
                                                isInvalid={
                                                    touched.initSocA &&
                                                    errors.initSocA !==
                                                        undefined
                                                }
                                                onChange={handleChange}
                                                value={values.initSocA}
                                                type="number"
                                            />
                                            {touched.initSocA &&
                                                errors.initSocA && (
                                                    <Text color="red.500">
                                                        {errors.initSocA}
                                                    </Text>
                                                )}
                                        </FormControl>
                                        <FormControl id="initSocB">
                                            <FormLabel>
                                                Initial Bound Charge [mJ]
                                            </FormLabel>
                                            <Input
                                                name="initSocB"
                                                id="initSocB"
                                                placeholder="Initial Bound Charge"
                                                _placeholder={{
                                                    color: 'gray.500',
                                                }}
                                                isInvalid={
                                                    touched.initSocB &&
                                                    errors.initSocB !==
                                                        undefined
                                                }
                                                onChange={handleChange}
                                                value={values.initSocB}
                                                type="number"
                                            />
                                            {touched.initSocB &&
                                                errors.initSocB && (
                                                    <Text color="red.500">
                                                        {errors.initSocB}
                                                    </Text>
                                                )}
                                        </FormControl>
                                        <FormControl id="c">
                                            <FormLabel>KiBaM c</FormLabel>
                                            <Input
                                                name="c"
                                                id="c"
                                                placeholder="KiBaM c"
                                                _placeholder={{
                                                    color: 'gray.500',
                                                }}
                                                isInvalid={
                                                    (touched.c &&
                                                        errors.c !==
                                                            undefined) ||
                                                    (touched.p &&
                                                        errors.p !== undefined)
                                                }
                                                onChange={handleChange}
                                                value={values.c}
                                                type="number"
                                            />
                                            {touched.c && errors.c && (
                                                <Text color="red.500">
                                                    {errors.c}
                                                </Text>
                                            )}
                                        </FormControl>
                                        <FormControl id="p">
                                            <FormLabel>KiBaM p</FormLabel>
                                            <Input
                                                name="p"
                                                id="p"
                                                placeholder="KiBaM p"
                                                _placeholder={{
                                                    color: 'gray.500',
                                                }}
                                                isInvalid={
                                                    (touched.c &&
                                                        errors.c !==
                                                            undefined) ||
                                                    (touched.p &&
                                                        errors.p !== undefined)
                                                }
                                                onChange={handleChange}
                                                value={values.p}
                                                type="number"
                                            />
                                            {touched.p && errors.p && (
                                                <Text color="red.500">
                                                    {errors.p}
                                                </Text>
                                            )}
                                        </FormControl>
                                    </Stack>
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        colorScheme="blue"
                                        mr={3}
                                    >
                                        Save
                                    </Button>
                                    <Button onClick={onClose}>Cancel</Button>
                                </ModalFooter>
                            </Form>
                        </>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};

export default BatteryModal;

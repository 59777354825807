import {
    Alert,
    AlertIcon,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BreadcrumbItemProps } from '../../components/Breadcrumb';
import Page from '../../components/Page';
import config from '../../Config';
import { useAuth } from '../../context/AuthContext';
import GroundStation from '../../types/GroundStation';
import GroundStationSchema from './GroundStationValidationSchema';

const breadCrumbItems: BreadcrumbItemProps[] = [
    {
        name: 'Home',
        to: config.REALTIME_PREFIX,
    },
    {
        name: 'Groundstation',
        to: `${config.REALTIME_PREFIX}/groundstations`,
    },
    {
        name: 'Create',
        to: `${config.REALTIME_PREFIX}/groundstations/create`,
    },
];



const GroundStationCreatePage = () => {
    const bgColor = useColorModeValue('white', 'gray.700');
    const { axiosInstance } = useAuth();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [checked, setChecked] = useState(false);

    const createGroundStation = useCallback(
        async (values: GroundStation) => {
            setError('');
            setLoading(true);
            try {
                await axiosInstance.post('/api/groundstations', values);
                history.push(`${config.REALTIME_PREFIX}/groundstations`);
            } catch (error: any) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                    setError(error.message);
                }
                setLoading(false);
            }
        },
        [axiosInstance, history]
    );

    return (
        <Page isLarge breadCrumbItems={breadCrumbItems}>
            <Stack
                p="4"
                bg={bgColor}
                rounded={'lg'}
                boxShadow={'lg'}
            >
                <Text fontWeight="semibold">Create a groundstation:</Text>
                {error && (
                    <Alert status="error">
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
                <Formik
                    initialValues={{
                        groundStationName: '',
                        longitude: 0,
                        latitude: 0,
                        altitude: 0,
                        elevation: 20.0,
                        maxcheck: 60.0,
                        threshold: 0.001,
                    }}
                    validationSchema={GroundStationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        setError('');
                        await createGroundStation(values);
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <Stack spacing={5} w={'full'}>
                                    <FormControl id="groundStationName">
                                        <FormLabel>Name</FormLabel>
                                        <Input
                                            name="groundStationName"
                                            id="groundStationName"
                                            placeholder="Groundstation name"
                                            _placeholder={{
                                                color: 'gray.500',
                                            }}
                                            disabled={loading}
                                            isInvalid={
                                                touched.groundStationName &&
                                                errors.groundStationName !== undefined
                                            }
                                            onChange={handleChange}
                                            value={values.groundStationName}
                                            type="text"
                                        />
                                        {touched.groundStationName && errors.groundStationName && (
                                            <Text color="red.500">
                                                {errors.groundStationName}
                                            </Text>
                                        )}
                                    </FormControl>
                                    <FormControl id="latitude">
                                        <FormLabel>Latitude</FormLabel>
                                        <Input
                                            name="latitude"
                                            id="latitude"
                                            placeholder="Groundstation latitude"
                                            _placeholder={{
                                                color: 'gray.500',
                                            }}
                                            disabled={loading}
                                            isInvalid={
                                                touched.latitude &&
                                                errors.latitude !== undefined
                                            }
                                            onChange={handleChange}
                                            value={values.latitude}
                                            type="number"
                                        />
                                        {touched.latitude &&
                                            errors.latitude && (
                                                <Text color="red.500">
                                                    {errors.latitude}
                                                </Text>
                                            )}
                                    </FormControl>
                                    <FormControl id="longitude">
                                        <FormLabel>Longitude</FormLabel>
                                        <Input
                                            name="longitude"
                                            id="longitude"
                                            placeholder="Groundstation longitude"
                                            _placeholder={{
                                                color: 'gray.500',
                                            }}
                                            disabled={loading}
                                            isInvalid={
                                                touched.longitude &&
                                                errors.longitude !== undefined
                                            }
                                            onChange={handleChange}
                                            value={values.longitude}
                                            type="number"
                                        />
                                        {touched.longitude &&
                                            errors.longitude && (
                                                <Text color="red.500">
                                                    {errors.longitude}
                                                </Text>
                                            )}
                                    </FormControl>
                                    <FormControl id="altitude">
                                        <FormLabel>Altitude</FormLabel>
                                        <Input
                                            name="altitude"
                                            id="altitude"
                                            placeholder="Groundstation altitude"
                                            _placeholder={{
                                                color: 'gray.500',
                                            }}
                                            disabled={loading}
                                            isInvalid={
                                                touched.altitude &&
                                                errors.altitude !== undefined
                                            }
                                            onChange={handleChange}
                                            value={values.altitude}
                                            type="number"
                                        />
                                        {touched.altitude &&
                                            errors.altitude && (
                                                <Text color="red.500">
                                                    {errors.altitude}
                                                </Text>
                                            )}
                                    </FormControl>
                                    <Divider />
                                    <Checkbox
                                        isChecked={checked}
                                        onChange={(event) => {
                                            setChecked(event.target.checked);
                                            if (
                                                event.target.checked === false
                                            ) {
                                                setFieldValue(
                                                    'elevation',
                                                    20.0,
                                                    true
                                                );
                                                setFieldValue(
                                                    'maxcheck',
                                                    60.0,
                                                    true
                                                );
                                                setFieldValue(
                                                    'threshold',
                                                    0.001,
                                                    true
                                                );
                                            }
                                        }}
                                    >
                                        I want to edit Orekit settings for this
                                        groundstations detector.
                                    </Checkbox>
                                    <FormControl id="elevation">
                                        <FormLabel>Elevation</FormLabel>
                                        <Input
                                            name="elevation"
                                            id="elevation"
                                            placeholder="Groundstation detector elevation"
                                            _placeholder={{
                                                color: 'gray.500',
                                            }}
                                            disabled={
                                                loading || checked === false
                                            }
                                            isInvalid={
                                                touched.elevation &&
                                                errors.elevation !== undefined
                                            }
                                            onChange={handleChange}
                                            value={values.elevation}
                                            type="text"
                                        />
                                        {touched.elevation &&
                                            errors.elevation && (
                                                <Text color="red.500">
                                                    {errors.elevation}
                                                </Text>
                                            )}
                                    </FormControl>
                                    <FormControl id="maxcheck">
                                        <FormLabel>Maxcheck</FormLabel>
                                        <Input
                                            name="maxcheck"
                                            id="maxcheck"
                                            placeholder="Groundstation detector maxcheck"
                                            _placeholder={{
                                                color: 'gray.500',
                                            }}
                                            disabled={
                                                loading || checked === false
                                            }
                                            isInvalid={
                                                touched.maxcheck &&
                                                errors.maxcheck !== undefined
                                            }
                                            onChange={handleChange}
                                            value={values.maxcheck}
                                            type="text"
                                        />
                                        {touched.maxcheck &&
                                            errors.maxcheck && (
                                                <Text color="red.500">
                                                    {errors.maxcheck}
                                                </Text>
                                            )}
                                    </FormControl>
                                    <FormControl id="threshold">
                                        <FormLabel>Threshold</FormLabel>
                                        <Input
                                            name="threshold"
                                            id="threshold"
                                            placeholder="Groundstation detector threshold"
                                            _placeholder={{
                                                color: 'gray.500',
                                            }}
                                            disabled={
                                                loading || checked === false
                                            }
                                            isInvalid={
                                                touched.threshold &&
                                                errors.threshold !== undefined
                                            }
                                            onChange={handleChange}
                                            value={values.threshold}
                                            type="text"
                                        />
                                        {touched.threshold &&
                                            errors.threshold && (
                                                <Text color="red.500">
                                                    {errors.threshold}
                                                </Text>
                                            )}
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                        isLoading={loading}
                                        colorScheme="blue"
                                        mr={3}
                                    >
                                        Submit
                                    </Button>
                                </Stack>
                            </Form>
                        </>
                    )}
                </Formik>
            </Stack>
        </Page>
    );
};

export default GroundStationCreatePage;

import { useColorModeValue } from '@chakra-ui/react';
import enGB from 'date-fns/locale/en-GB';
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CommonStyle.css';

const CustomReactDatepicker = ({ error, ...rest }: any) => {
    const colorMode = useColorModeValue('light', 'dark');

    useEffect(() => {
        const loadStyleSheet = async () => {
            if (colorMode === 'dark') {
                await import('./DarkStyle.css');
            }
        };
        loadStyleSheet();
    }, [colorMode]);

    return (
        <DatePicker
            {...rest}
            className={`${colorMode} ${error && 'error'}`}
            locale={enGB}
        />
    );
};

export default CustomReactDatepicker;

import {
    Button,
    Flex,
    FormControl,
    Heading,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Page from '../components/Page';
import { useAuth } from '../context/AuthContext';

const TokenPage = () => {
    const history = useHistory();
    const { login } = useAuth();
    const [tokenInput, setTokenInput] = useState('');
    const [showToken, setShowToken] = useState(false);
    const [error, setError] = useState('');

    const validateToken = async () => {
        const { result, error } = await login(tokenInput);
        if (result) {
            history.push('/');
        } else {
            setError(error);
        }
    };

    return (
        <Page>
            <Flex minH={'81vh'} align={'center'} justify={'center'}>
                <Stack
                    spacing={4}
                    w={'full'}
                    maxW={'md'}
                    bg={useColorModeValue('white', 'gray.700')}
                    rounded={'xl'}
                    boxShadow={'lg'}
                    p={6}
                    my={12}
                >
                    <Heading
                        lineHeight={1.1}
                        fontSize={{ base: '2xl', md: '3xl' }}
                    >
                        Set Token.
                    </Heading>
                    <Text
                        fontSize={{ base: 'sm', sm: 'md' }}
                        color={useColorModeValue('gray.800', 'gray.400')}
                    >
                        The token will be validated.
                    </Text>
                    <FormControl id="currentToken">
                        <InputGroup size="md">
                            <Input
                                placeholder="Your current token"
                                _placeholder={{ color: 'gray.500' }}
                                type={showToken ? 'text' : 'password'}
                                value={tokenInput}
                                isInvalid={error !== ''}
                                errorBorderColor={'red.500'}
                                onChange={(event) => {
                                    setTokenInput(event.target.value);
                                }}
                            />
                            <InputRightElement width="4.5rem">
                                <Button
                                    h="1.75rem"
                                    size="sm"
                                    onClick={() =>
                                        setShowToken((old) => {
                                            return !old;
                                        })
                                    }
                                >
                                    {showToken ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {error !== '' && (
                            <Text
                                fontSize={{ base: 'sm', sm: 'md' }}
                                color={'red.500'}
                            >
                                {error}
                            </Text>
                        )}
                    </FormControl>
                    <Stack spacing={6}>
                        <Button
                            onClick={() => validateToken()}
                            bg={'blue.400'}
                            color={'white'}
                            _hover={{
                                bg: 'blue.500',
                            }}
                        >
                            Set token
                        </Button>
                    </Stack>
                </Stack>
            </Flex>
        </Page>
    );
};

export default TokenPage;

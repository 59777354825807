import { Box, Container, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Breadcrumb, { BreadcrumbItemProps } from './Breadcrumb';

interface Props {
    breadCrumbItems?: BreadcrumbItemProps[];
    loading?: boolean;
    children?: ReactNode;
    isLarge?: boolean;
    title?: string;
}

const Page = ({ breadCrumbItems, loading = false, isLarge = false, title, children }: Props) => {
    return (
        <Box overflowY="scroll" flex="1" bg={useColorModeValue('gray.50', 'gray.800')}>
            <Container minH={'100%'} maxW={isLarge ? 'container.xl' : 'container.lg'}>
                <Stack spacing={4} minH={'100%'} mb={"50px"}>
                    {breadCrumbItems && (
                        <Box mt={4}>
                            <Breadcrumb items={breadCrumbItems} />
                        </Box>
                    )}
                    {title && (
                        <Text
                            mt={'5px'}
                            as="h1"
                            fontSize="4xl"
                            fontWeight="semibold"
                            textAlign={{ base: 'center', xl: 'left' }}
                        >
                            {title}
                        </Text>
                    )}
                    {children}
                </Stack>
            </Container>
        </Box>
    );
};

export default Page;

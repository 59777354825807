const common = {
    LOGIN_ROUTE: '/login',
    LOGOUT_ROUTE: '/logout',
    SETTINGS_ROUTE: '/settings',
    LOGS_ROUTE: '/logs',
    REALTIME_PREFIX: '/rt',
    SIMULATOR_PREFIX: '/sim',
};

const prod = {
    API_URL: 'https://satsim.leopowver.space',
    WS_URL: 'wss://satsim.leopowver.space/simulation',
    ...common,
};

const dev = {
    API_URL: 'http://localhost:8080',
    WS_URL: 'ws://localhost:8080/simulation',
    ...common,
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;

import { Button, HStack, Tooltip, useColorModeValue, useToast } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import config from '../../../Config';
import { useAuth } from '../../../context/AuthContext';
import { useSatDetailContext } from '../../../context/SatelliteDetailContext';

const DebugActions = () => {
    const history = useHistory();
    const bgColor = useColorModeValue('red.500', 'red.500');
    const bgColorHover = useColorModeValue('red.400', 'red.400');
    const toast = useToast();
    const { satellite } = useSatDetailContext();
    const { axiosInstance } = useAuth();

    const forceUpload = useCallback(async () => {
        if (!window.confirm(`Do you really want to force upload for the satellite \`${satellite.satelliteName}\`?`)) return;
        try {
            await axiosInstance.post(
                `/api/debug/${satellite.satelliteName}/force-upload/`
            );
            toast({
                title: "Upload Successful",
                description: "Operations were force-uploaded to the satellite.",
                status: "success",
                duration: 3000,
                position: "top",
                isClosable: true,
            });
        } catch (err) {
            console.error(err);
            toast({
                title: "Upload Failed",
                description: "Something went wrong while trying to force-upload.",
                status: "error",
                duration: 3000,
                position: "top",
                isClosable: true,
            });
        }
    }, [satellite, axiosInstance, toast]);

    const forceContact = useCallback(async () => {
        if (!window.confirm(`Do you really want to force contact for the satellite \`${satellite.satelliteName}\`?`)) return;
        try {
            await axiosInstance.post(
                `/api/debug/${satellite.satelliteName}/force-contact/`
            );
            toast({
                title: "Contact Successful",
                description: "Telemetry download succeeded.",
                status: "success",
                duration: 3000,
                position: "top",
                isClosable: true,
            });
        } catch (err) {
            console.error(err);
            toast({
                title: "Contact Failed",
                description: "Something went wrong while trying to force-contact.",
                status: "error",
                duration: 3000,
                position: "top",
                isClosable: true,
            });
        }
    }, [satellite, axiosInstance, toast]);

    const deleteSatellite = useCallback(async () => {
        if (!window.confirm(`Do you really want to delete the satellite \`${satellite.satelliteName}\`?`)) return;
        try {
            await axiosInstance.delete(`/api/satellites/${satellite.satelliteName}/`);
            history.push(`${config.REALTIME_PREFIX}/satellites`);
        } catch (err) {
            console.error(err);
            toast({
                title: "Deletion Failed",
                description: "Something went wrong while trying to delete the Satellite.",
                status: "error",
                duration: 3000,
                position: "top",
                isClosable: true,
            });
        }
    }, [history, satellite, axiosInstance, toast]);

    return (
        <HStack mt={4}>
            <Tooltip
                hasArrow
                label="Forces the groundstation to upload the operations to the satellite. Upload is successful even if satellite is not reachable."
                fontSize="sm"
            >
                <Button bg={bgColor} _hover={{ bg: bgColorHover }} onClick={forceUpload}>
                    Force-Upload
                </Button>
            </Tooltip>
            <Tooltip
                hasArrow
                label="Forces the satellite to send its telemetry to the ground station. Download is successful even if they have no contact."
                fontSize="sm"
            >
                <Button bg={bgColor} _hover={{ bg: bgColorHover }} onClick={forceContact}>
                    Force-Contact
                </Button>
            </Tooltip>
            <Tooltip
                hasArrow
                label="Deletes Satellite and all of its components, Battery and telemetry data including LoadTraces and BatteryCharges. This action cannot be undone!"
                fontSize="sm"
            >
                <Button
                    variant='outline'
                    colorScheme={"red"}
                    onClick={deleteSatellite}
                >
                    Delete Satellite
                </Button>
            </Tooltip>
        </HStack>
    );
};

export default DebugActions;

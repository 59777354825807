import { ReactNode } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { Simulation } from '../types/Simulation';
import { SimulationContext } from './SimulationContext';
interface Props {
    children?: ReactNode;
}

const SimulationProvider = ({ children }: Props) => {
    const [storedSimulations, setStoredSimulations] = useLocalStorage<Simulation[]>('sat-sim-simulations', []);

    return (
        <SimulationContext.Provider
            value={{
                storedSimulations,
                setStoredSimulations,
            }}
        >
            {children}
        </SimulationContext.Provider>
    );
};

export default SimulationProvider;

import { ChevronDownIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { IconButton, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useViewportSize } from '@mantine/hooks';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import CustomCard from '../../components/CustomCard';
import config from '../../Config';
import { useSimulationContext } from '../../context/SimulationContext';
import { Simulation } from '../../types/Simulation';
import { createTimeString } from '../../utils/createTimeString';

const PREFIX = config.SIMULATOR_PREFIX;

const PreviousSimulation = ({ simulation }: { simulation: Simulation }) => {
    const { setStoredSimulations } = useSimulationContext();
    const history = useHistory();
    const { width } = useViewportSize();
    const deleteSimulation = useCallback(() => {
        setStoredSimulations((oldSimulations) => {
            const tempSimulations = [...oldSimulations];
            return tempSimulations.filter((sim) => sim.id !== simulation.id);
        });
    }, [simulation, setStoredSimulations]);

    return (
        <Tr>
            <Td>{simulation.name}</Td>
            <Td>{createTimeString(new Date(simulation.created))}</Td>
            {width >= 1020 && (
                <>
                    <Td>{`${createTimeString(new Date(simulation.startDate))} - ${createTimeString(
                        new Date(simulation.endDate)
                    )}`}</Td>
                    <Td>{simulation.seed ? simulation.seed : '-'}</Td>
                </>
            )}

            <Td>{simulation.satellite.name}</Td>
            <Td isNumeric>
                <Menu>
                    <MenuButton as={IconButton} icon={<ChevronDownIcon />} />
                    <MenuList>
                        <MenuItem icon={<EditIcon />} onClick={() => history.push(`${PREFIX}/run/${simulation.id}`)}>
                            Open
                        </MenuItem>
                        <MenuItem icon={<DeleteIcon />} onClick={deleteSimulation}>
                            Delete
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Td>
        </Tr>
    );
};

const PreviousSimulationsOverview = () => {
    const { storedSimulations } = useSimulationContext();
    const { width } = useViewportSize();
    return (
        <CustomCard>
            <Table colorScheme="teal" maxW={"100%"}>
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Created</Th>
                        {width >= 1020 && (
                            <>
                                <Th>Time</Th>
                                <Th>Seed</Th>
                            </>
                        )}
                        <Th>Satellite</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {storedSimulations.map((simulation) => (
                        <PreviousSimulation key={simulation.id} simulation={simulation} />
                    ))}
                </Tbody>
            </Table>
        </CustomCard>
    );
};

export default PreviousSimulationsOverview;

import * as Yup from 'yup';

const GroundStationSchema = Yup.object().shape({
    groundStationName: Yup.string().required('A name is required.'),
    longitude: Yup.number().required('A longitude value is required.'),
    latitude: Yup.number().required('A latitude value is required.'),
    altitude: Yup.number().required('An altitude value is required.'),
    elevation: Yup.number().required('An elevation value is required.'),
    maxcheck: Yup.number().required('An maxCheck value is required.'),
    threshold: Yup.number().required('A threshold value is required.'),
});

export default GroundStationSchema;

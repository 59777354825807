import Axios from 'axios';
import { ReactNode, useCallback, useMemo } from 'react';
import Config from '../Config';
import useLocalStorage from '../hooks/useLocalStorage';
import { AuthContext } from './AuthContext';
interface Props {
    children?: ReactNode;
}

const AuthProvider = ({ children }: Props) => {
    const [token, setToken] = useLocalStorage('sat-sim-token', '');

    const axiosInstance = useMemo(() => {
        const axios = Axios.create({
            baseURL: Config.API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        axios.interceptors.response.use(undefined, (error) => {
            if (error.response?.data) {
                const data = error.response.data;
                if (data.status === 401) {
                    setToken('');
                }
                return Promise.reject(data);
            }
            return Promise.reject(error.message);
        });

        return axios;
    }, [token, setToken]);

    const isAuthenticated = useMemo(() => {
        return token !== '';
    }, [token]);

    const logout = useCallback(() => {
        setToken('');
    }, [setToken]);

    const login = useCallback(
        async (input: string) => {
            try {
                await Axios.get(`${Config.API_URL}/api/satellites`, {
                    headers: {
                        Authorization: `Bearer ${input}`,
                    },
                });
                setToken(input);
                return {
                    result: true,
                    error: '',
                };
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    setToken('');
                    return {
                        result: false,
                        error: 'Invalid token.',
                    };
                } else {
                    setToken('');
                    return {
                        result: false,
                        error: 'Server is not responding.',
                    };
                }
            }
        },
        [setToken]
    );

    return (
        <AuthContext.Provider
            value={{ token, isAuthenticated, logout, login, axiosInstance }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

import { Route, Switch } from 'react-router';
import config from '../../Config';
import SimulationCreateProvider from '../../context/SimulationCreateProvider';
import SimulationProvider from '../../context/SimulationProvider';
import NotFound from '../../pages/NotFound';
import SimulationCreatePage from '../../pages/SimulationCreatePage';
import SimulationOverviewPage from '../../pages/SimulationOverviewPage';
import SimulationRunPage from '../../pages/SimulationRunPage';

const PREFIX = config.SIMULATOR_PREFIX;

const Router = () => {
    return (
        <SimulationProvider>
            <Switch>
                <Route exact path={PREFIX}>
                    <SimulationOverviewPage />
                </Route>
                <Route path={`${PREFIX}/run/:id`}>
                    <SimulationRunPage />
                </Route>
                <Route exact path={`${PREFIX}/create`}>
                    <SimulationCreateProvider>
                        <SimulationCreatePage />
                    </SimulationCreateProvider>
                </Route>
                <Route path="*">
                    <NotFound prefix={PREFIX} />
                </Route>
            </Switch>
        </SimulationProvider>
    );
};

export default Router;

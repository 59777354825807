import * as Yup from 'yup';

const ComponentSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required.'),
    background: Yup.boolean()
        .required('Background is required.'),
    slewingBefore: Yup.number()
        .min(0, "SlewingBefore duration must be >= 0.")
        .required('Slewing before duration is required.'),
    slewingAfter: Yup.number()
        .min(0, "SlewingAfter duration must be >= 0.")
        .required('Slewing after duration is required.'),
    powerConsumption: Yup.number()
        .required('Power consumption is required.'),
});

export default ComponentSchema;

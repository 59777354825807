import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';

const METRIC_NAMES = [
    { name: 'systemCores', metric: 'system.cpu.count' },
    { name: 'systemCPUUsage', metric: 'system.cpu.usage' },
    { name: 'processCPUUsage', metric: 'process.cpu.usage'},
    { name: 'processStartTime', metric: 'process.start.time'},
    { name: 'processUpTime', metric: 'process.uptime'},
    { name: 'jvmUsedMemory', metric: 'jvm.memory.used'},
    { name: 'jvmMaxMemory', metric: 'jvm.memory.max'},
];

interface Metrics {
    systemCores: number;
    systemCPUUsage: number;
    processCPUUsage: number;
    processStartTime: number;
    processUpTime: number;
    jvmUsedMemory: number;
    jvmMaxMemory: number;
}

interface ItemProps {
    name: String;
    value: String;
}
const Item = ({ name, value }: ItemProps) => {
    return (
        <HStack>
            <Box fontWeight="semibold">{name}</Box>
            <Box fontSize={'small'}>{value}</Box>
        </HStack>
    );
};

export const MetricsInfo = () => {
    const { axiosInstance } = useAuth();

    const [metrics, setMetrics] = useState<Metrics>({
        systemCores: 0,
        systemCPUUsage: 0,
        processCPUUsage: 0,
        processStartTime: 0,
        processUpTime: 0,
        jvmUsedMemory: 0,
        jvmMaxMemory: 0,
    });

    const loadMetrics = useCallback(
        async () => {
            const newMetrics: Metrics = {
                systemCores: 0,
                systemCPUUsage: 0,
                processCPUUsage: 0,
                processStartTime: 0,
                processUpTime: 0,
                jvmUsedMemory: 0,
                jvmMaxMemory: 0,
            };
            await Promise.all(
                METRIC_NAMES.map(async (metric) => {
                    const res = await axiosInstance.get(
                        `/api/metrics/${metric.metric}`
                    );
                    if (
                        metric.name === 'systemCores' ||
                        metric.name === 'systemCPUUsage' ||
                        metric.name === 'processCPUUsage' ||
                        metric.name === 'processStartTime' ||
                        metric.name === 'processUpTime' ||
                        metric.name === 'jvmUsedMemory' ||
                        metric.name === 'jvmMaxMemory'
                    ) {
                        newMetrics[metric.name] = res.data.measurements[0].value;
                    }
                })
            );
            setMetrics(newMetrics);
        },
        [axiosInstance]
    );

    useEffect(() => {
        loadMetrics();
    }, [loadMetrics]);

    useEffect(() => {
        const timeout = setInterval(() => {
            loadMetrics();
        }, 4000);
        return () => {
            clearInterval(timeout);
        };
    }, [loadMetrics]);

    return (
        <Stack flexDir={{ base: 'column' }} justifyContent="space-between">
            <Stack pl={{ base: '7', sm: '0' }}>
                <Text>System:</Text>
                <HStack
                    spacing={{ base: '0', sm: '10' }}
                    flexDir={{ base: 'column', sm: 'row' }}
                    align={{ base: 'flex-start', sm: 'flex-end' }}
                >
                    <Item
                        name={'CPU Cores:'}
                        value={metrics.systemCores.toString()}
                    />
                    <Item
                        name={'CPU Usage:'}
                        value={`${(metrics.systemCPUUsage * 100).toFixed(2)} %`}
                    />
                </HStack>
                <Text>Process:</Text>
                <HStack
                    spacing={{ base: '0', sm: '10' }}
                    flexDir={{ base: 'column', sm: 'row' }}
                    align={{ base: 'flex-start', sm: 'flex-end' }}
                >
                    <Item
                        name={'CPU Usage:'}
                        value={`${(metrics.processCPUUsage * 100).toFixed(
                            2
                        )} %`}
                    />
                    <Item
                        name={'Start time:'}
                        value={new Date(
                            metrics.processStartTime * 1000
                        ).toString()}
                    />
                    <Item
                        name={'Uptime:'}
                        value={`${(metrics.processUpTime / 60 / 60).toFixed(
                            2
                        )} h`}
                    />
                </HStack>
                <Text>JVM:</Text>
                <HStack
                    spacing={{ base: '0', sm: '10' }}
                    flexDir={{ base: 'column', sm: 'row' }}
                    align={{ base: 'flex-start', sm: 'flex-end' }}
                >
                    <Item
                        name={'Used memory:'}
                        value={`${(metrics.jvmUsedMemory / 1000 / 1000).toFixed(
                            3
                        )} MB`}
                    />
                    <Item
                        name={'Max memory:'}
                        value={`${(metrics.jvmMaxMemory / 1000 / 1000).toFixed(
                            3
                        )} MB`}
                    />
                </HStack>
            </Stack>
        </Stack>
    );
};

export default MetricsInfo;

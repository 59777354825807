import axios, { AxiosInstance } from 'axios';
import { createContext, useContext } from 'react';

type ContextType = {
    token: string;
    isAuthenticated: boolean;
    login: (input: string) => Promise<{
        result: boolean;
        error: string;
    }>;
    logout: () => void;
    axiosInstance: AxiosInstance;
};

export const AuthContext = createContext<ContextType>({
    token: '',
    isAuthenticated: false,
    login: async (input: string) => {
        return {
            result: false,
            error: 'string',
        };
    },
    logout: () => {},
    axiosInstance: new Proxy(axios.create(), {
        apply: () => {
            throw new Error('You must wrap your component in an AxiosProvider');
        },
        get: () => {
            throw new Error('You must wrap your component in an AxiosProvider');
        },
    }),
});

export const useAuth = () => useContext(AuthContext);

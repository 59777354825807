import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useSatDetailContext } from '../../../context/SatelliteDetailContext';
// import { useSettings } from '../../../context/SettingsContext';

const SatelliteComponentOverview = () => {
    // const { debug } = useSettings();
    const { satellite } = useSatDetailContext();
    return (
        <Table size="sm">
            <Thead>
                <Tr>
                    <Th isTruncated>
                        <Text fontWeight="semibold">ID:</Text>
                    </Th>
                    <Th isTruncated>
                        <Text fontWeight="semibold">Component-Name:</Text>
                    </Th>
                    <Th isTruncated isNumeric>
                        <Text fontWeight="semibold">Background:</Text>
                    </Th>
                    <Th isTruncated isNumeric>
                        <Text fontWeight="semibold">Slewing Before [min]:</Text>
                    </Th>
                    <Th isTruncated isNumeric>
                        <Text fontWeight="semibold">Slewing After [min]:</Text>
                    </Th>
                    <Th isTruncated isNumeric>
                        <Text fontWeight="semibold">Power Consumption:</Text>
                    </Th>
                    {/* {debug && (
                        <Th isTruncated isNumeric>
                            <Text fontWeight="semibold">Active:</Text>
                        </Th>
                    )} */}
                </Tr>
            </Thead>
            <Tbody>
                {satellite.components.map((component, index) => (
                    <Tr key={`${component.name}`}>
                        <Td isTruncated>
                            <Text>{index + 1}</Text>
                        </Td>
                        <Td isTruncated>
                            <Text fontSize="sm">{component.name}</Text>
                        </Td>
                        <Td isTruncated isNumeric>
                            <Text fontSize="sm">
                                {component.background ? '✔️' : '❌'}
                            </Text>
                        </Td>
                        <Td isTruncated isNumeric>
                            <Text fontSize="sm">
                                {component.slewingBefore}
                            </Text>
                        </Td>
                        <Td isTruncated isNumeric>
                            <Text fontSize="sm">
                                {component.slewingAfter}
                            </Text>
                        </Td>
                        <Td isTruncated isNumeric>
                            <Text fontSize="sm">
                                {component.powerConsumption}
                            </Text>
                        </Td>
                        {/* {debug && (
                            <Th isTruncated isNumeric>
                                <Text fontWeight="semibold">
                                    {component.currentlyActive ? '✔️' : '❌'}
                                </Text>
                            </Th>
                        )} */}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

export default SatelliteComponentOverview;

import { Route, Switch } from 'react-router';
import config from '../../Config';
import FleetViewPage from '../../pages/FleetViewPage';
import GroundStationCreatePage from '../../pages/GroundStationCreatePage';
import GroundStationOverviewPage from '../../pages/GroundStationOverviewPage';
import HomePage from '../../pages/HomePage/HomePage';
import LogPage from '../../pages/LogPage';
import NotFound from '../../pages/NotFound';
import SatelliteCreatePage from '../../pages/SatelliteCreatePage';
import SatelliteDetailPage from '../../pages/SatelliteDetailPage';
import SatelliteOverviewPage from '../../pages/SatelliteOverviewPage';
import SettingsPage from '../../pages/SettingsPage';

const PREFIX = config.REALTIME_PREFIX;

const Router = () => {
    return (
        <Switch>
            <Route exact path={`${PREFIX}`}>
                <HomePage />
            </Route>
            <Route exact path={`${PREFIX}/logs`}>
                <LogPage />
            </Route>
            <Route exact path={`${PREFIX}/settings`}>
                <SettingsPage />
            </Route>

            {/* Satellite Routes */}
            <Route exact path={`${PREFIX}/satellites`}>
                <SatelliteOverviewPage />
            </Route>
            <Route path={`${PREFIX}/satellites/detail/:satName`}>
                <SatelliteDetailPage />
            </Route>
            <Route path={`${PREFIX}/satellites/create`}>
                <SatelliteCreatePage />
            </Route>

            {/* Groundstation Routes */}
            <Route exact path={`${PREFIX}/groundstations/create`}>
                <GroundStationCreatePage />
            </Route>
            <Route exact path={`${PREFIX}/groundstations`}>
                <GroundStationOverviewPage />
            </Route>

            {/* Fleetview routes */}
            <Route exact path={`${PREFIX}/fleetview`}>
                <FleetViewPage />
            </Route>
            <Route path="*">
                <NotFound prefix={PREFIX} />
            </Route>
        </Switch>
    );
};

export default Router;

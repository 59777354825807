import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
    HStack,
    IconButton,
    Select,
    Text,
    useColorModeValue,
    VStack
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CustomCard from '../components/CustomCard';
import Page from '../components/Page';
import { useAuth } from '../context/AuthContext';
import LogEntry from '../types/LogEntry';

const LogPage = () => {
    const textColor = useColorModeValue('black', 'white');
    const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
    const [page, setPage] = useState(0);
    const [entriesPerPage, setEntriesPerPage] = useState(150);
    const [logsForPastDays, setLogsForPastDays] = useState(7);
    const { axiosInstance } = useAuth();

    const maxPage = useMemo(() => {
        setPage(0);
        return Math.ceil(logEntries.length / entriesPerPage) - 1;
    }, [logEntries, setPage, entriesPerPage]);

    const shownEntries = useMemo(() => {
        const startIndex = page * entriesPerPage;
        const endIndex = (page + 1) * entriesPerPage;
        return [...logEntries].reverse().slice(startIndex, endIndex);
    }, [logEntries, page, entriesPerPage]);

    const loadLogEntries = useCallback(async () => {
        const apiData = await axiosInstance.get<LogEntry[]>(`/api/log`, {
            params: {
                includeDays: logsForPastDays,
            },
        });
        setPage(0);
        setLogEntries(apiData.data);
    }, [axiosInstance, logsForPastDays]);

    useEffect(() => {
        loadLogEntries();
    }, [loadLogEntries]);

    return (
        <Page title={"Logs"}>
            <CustomCard>
                <VStack
                    align="flex-start"
                    justify="center"
                    spacing="5"
                    border={useColorModeValue('1px', '0px')}
                    mb={5}
                    borderColor="gray.100"
                    p="4"
                    shadow="md"
                    rounded="sm"
                    bg={useColorModeValue('white', 'gray.600')}
                >
                    <HStack spacing={'20px'}>
                        <VStack>
                            <Text>Entries per page:</Text>
                            <Select
                                size="sm"
                                isReadOnly
                                value={entriesPerPage}
                                onChange={(val) => {
                                    setEntriesPerPage(
                                        Number.parseInt(val.target.value)
                                    );
                                }}
                            >
                                <option value={50}>50</option>
                                <option value={150}>150</option>
                                <option value={500}>500</option>
                            </Select>
                        </VStack>
                        <VStack>
                            <Text>Logs for past days:</Text>
                            <Select
                                size="sm"
                                isReadOnly
                                value={logsForPastDays}
                                onChange={(val) => {
                                    setLogsForPastDays(
                                        Number.parseInt(val.target.value)
                                    );
                                }}
                            >
                                <option value={7}>7</option>
                                <option value={30}>30</option>
                                <option value={60}>60</option>
                                <option value={365}>365</option>
                            </Select>
                        </VStack>
                    </HStack>
                    <HStack width="100%" align="center">
                        <VStack width="100%">
                            <Text>Current page:</Text>
                            <HStack>
                                <IconButton
                                    colorScheme="blue"
                                    aria-label="Decrease page number."
                                    icon={<ArrowLeftIcon />}
                                    onClick={() => {
                                        setPage((oldNumber) => {
                                            if (oldNumber - 1 <= 0) {
                                                return 0;
                                            }
                                            return oldNumber - 1;
                                        });
                                    }}
                                />
                                <Text>{`${page + 1} / ${maxPage + 1}`}</Text>
                                <IconButton
                                    colorScheme="blue"
                                    aria-label="Increase page number."
                                    icon={<ArrowRightIcon />}
                                    onClick={() => {
                                        setPage((oldNumber) => {
                                            if (oldNumber + 1 >= maxPage) {
                                                return maxPage;
                                            }
                                            return oldNumber + 1;
                                        });
                                    }}
                                />
                            </HStack>
                        </VStack>
                    </HStack>
                </VStack>
                <VStack
                    pt="20px"
                    p="7px"
                    align="flex-start"
                    fontSize="small"
                    spacing="0.5"
                    textOverflow="ellipsis"
                    bg={useColorModeValue('gray.50', 'gray.600')}
                >
                    {shownEntries.map((logEntry) => {
                        let color = textColor;
                        switch (logEntry.logLevel) {
                            case 'WARN':
                                color = 'orange';
                                break;
                            case 'ERROR':
                                color = 'red';
                                break;
                        }
                        return (
                            <Text
                                key={logEntry.message}
                                color={color}
                            >{`${logEntry.logLevel} | ${logEntry.message}`}</Text>
                        );
                    })}
                </VStack>
            </CustomCard>
        </Page>
    );
};

export default LogPage;

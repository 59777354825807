import { Button, Fade, Flex } from '@chakra-ui/react';
import {
    Cartesian2,
    Cartesian3, Color,
    JulianDate, PolylineGlowMaterialProperty,
    SampledPositionProperty, Viewer as CesiumViewer
} from 'cesium';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CesiumComponentRef, Viewer } from 'resium';
import {
    eciToGeodetic,
    EciVec3,
    gstime,
    propagate,
    twoline2satrec
} from 'satellite.js';
import { useSatDetailContext } from '../../context/SatelliteDetailContext';
import { useSettings } from '../../context/SettingsContext';

interface Props {
    tle: string;
}

const SatelliteLocation = ({ tle }: Props) => {
    const { satellite } = useSatDetailContext();
    const { initialShowSatLocation } = useSettings();
    const [show, setShow] = useState(initialShowSatLocation);

    const viewerRef = useRef<CesiumComponentRef<CesiumViewer>>(null);
    const initLocation = useCallback(() => {
        if (viewerRef.current?.cesiumElement) {
            const satrec = twoline2satrec(
                tle.split('\n')[0].trim(),
                tle.split('\n')[1].trim()
            );

            const viewer = viewerRef.current.cesiumElement;
            const totalSeconds = 60 * 60 * 6;
            const timestepInSeconds = 10;
            const start = JulianDate.fromDate(new Date());
            const stop = JulianDate.addSeconds(
                start,
                totalSeconds,
                new JulianDate()
            );
            viewer.clock.startTime = start.clone();
            viewer.clock.stopTime = stop.clone();
            viewer.clock.currentTime = start.clone();
            viewer.timeline.zoomTo(start, stop);
            viewer.clock.multiplier = 1;
            // TODO: Fix after new version is released; Switch Back to ClockRange.LOOP_STOP.
            // viewer.clock.clockRange = ClockRange.LOOP_STOP;
            viewer.clock.clockRange = 2;
            viewer.scene.globe.enableLighting = true;

            const positionsOverTime = new SampledPositionProperty();
            for (let i = 0; i < totalSeconds; i += timestepInSeconds) {
                const time = JulianDate.addSeconds(start, i, new JulianDate());
                const jsDate = JulianDate.toDate(time);

                const positionAndVelocity = propagate(satrec, jsDate);
                const gmst = gstime(jsDate);
                const p = eciToGeodetic(
                    positionAndVelocity.position as EciVec3<number>,
                    gmst
                );

                const position = Cartesian3.fromRadians(
                    p.longitude,
                    p.latitude,
                    p.height * 1000
                );
                positionsOverTime.addSample(time, position);
            }

            viewer.entities.removeAll();

            satellite.groundStations.forEach((gs) => {
                viewer.entities.add({
                    name: gs.groundStationName,
                    position: Cartesian3.fromDegrees(gs.longitude, gs.latitude, gs.altitude),
                    point: {
                        pixelSize: 4,
                        color: Color.RED,
                        outlineColor: Color.WHITE,
                        outlineWidth: 1,
                    },
                    label: {
                        text: gs.groundStationName,
                        font: '12px sans-serif',
                        // TODO: Fix after new version is released; Switch Back to LabelStyle.FILL_AND_OUTLINE.
                        // style: LabelStyle.FILL_AND_OUTLINE,
                        style: 2,
                        outlineWidth: 0.2,
                        // TODO: Fix after new version is released; Switch Back to VerticalOrigin.BOTTOM.
                        // verticalOrigin: VerticalOrigin.BOTTOM,
                        verticalOrigin: 1,
                        pixelOffset: new Cartesian2(0, -9),
                    },
                });
            });


            // Visualize the satellite with a red dot.
            const satellitePoint = viewer.entities.add({
                position: positionsOverTime,
                point: { pixelSize: 5, color: Color.RED },
                label: {
                    text: satellite.satelliteName,
                    font: '12px sans-serif',
                    // TODO: Fix after new version is released; Switch Back to LabelStyle.FILL_AND_OUTLINE.
                    // style: LabelStyle.FILL_AND_OUTLINE,
                    style: 2,
                    outlineWidth: 1,
                    // TODO: Fix after new version is released; Switch Back to VerticalOrigin.BOTTOM.
                    // verticalOrigin: VerticalOrigin.BOTTOM,
                    verticalOrigin: 1,
                    pixelOffset: new Cartesian2(0, -9),
                },
                path: {
                    leadTime: 60 * 60 * 1,
                    trailTime: 0,
                    resolution: 1,
                    material: new PolylineGlowMaterialProperty({
                        glowPower: 0.1,
                        color: Color.ROYALBLUE,
                    }),
                    width: 10,
                },
            });

            viewer.trackedEntity = satellitePoint;

            let initialized = false;
            viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
                if (!initialized && viewer.scene.globe.tilesLoaded === true) {
                    viewer.clock.shouldAnimate = true;
                    initialized = true;
                    viewer.scene.camera.zoomOut(7000000);
                }
            });
        }
    }, [tle, satellite.satelliteName, satellite.groundStations]);

    useEffect(() => {
        if (initialShowSatLocation) {
            setTimeout(() => initLocation(), 1000);
        }
    }, [initLocation, initialShowSatLocation]);

    useEffect(() => {
        if (show) {
            initLocation();
        }
    }, [initLocation, show]);

    let body = null;
    if (!show) {
        body = (
            <Flex>
                <Button
                    onClick={() => {
                        setShow(true);
                    }}
                >
                    Compute and show location
                </Button>
            </Flex>
        );
    } else {
        body = (
            <Fade in={show}>
                <Viewer
                    ref={viewerRef}
                    homeButton={false}
                    fullscreenButton={false}
                    scene3DOnly={true}
                    baseLayerPicker={false}
                    geocoder={false}
                    infoBox={false}
                    navigationHelpButton={false}
                    sceneModePicker={false}
                />
            </Fade>
        );
    }

    return <>{body}</>;
};

export default SatelliteLocation;
